import { Popover, PopoverPosition, PopoverProps } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useSearch } from '../../../hooks/search.hooks';
import { MENU_ITEM_HEIGHT } from '../../../../../shared/constants';
import {
  SelectMenuWidth,
  SelectMenuWidhts,
  SelectListChildren,
} from '../menu.model';
import SelectList from './SelectList';

interface SelectMenuProps<T> extends Omit<PopoverProps, 'children' | 'open'> {
  items: T[];
  anchor?: Element;
  anchorPosition?: PopoverPosition;
  enableSearch?: boolean;
  enableVirtualization?: boolean;
  width?: SelectMenuWidth | number;
  onClose: () => void;
  onSearch?: (searchString: string) => void;
  children: SelectListChildren<T>;
}

const SelectMenu = <T,>({
  items,
  anchor,
  anchorPosition,
  enableSearch = false,
  enableVirtualization = false,
  width = 'small',
  onClose,
  onSearch,
  children,
  ...popoverProps
}: SelectMenuProps<T>) => {
  const { filteredItems, searchString, setSearchString } = useSearch({ items });

  const popoverSlotProps = useMemo<PopoverProps['slotProps']>(() => {
    const padding = 6;
    const itemsLength = enableSearch
      ? filteredItems.length + 1
      : filteredItems.length;

    const h = itemsLength * MENU_ITEM_HEIGHT + padding * 2;
    const w = typeof width === 'number' ? width : SelectMenuWidhts[width];

    return {
      paper: {
        sx: {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 750,
          paddingTop: enableVirtualization ? padding + 'px' : 0,
          height: enableVirtualization ? h : 'auto',
          width: enableVirtualization ? w : 'auto',
        },
      },
    };
  }, [enableSearch, filteredItems.length, width, enableVirtualization]);

  const handleSearch = useCallback(
    (searchString: string) => {
      onSearch?.(searchString);
      setSearchString(searchString);
    },
    [onSearch, setSearchString],
  );

  const renderChildren = useCallback<SelectListChildren<T>>(
    (item, style, index) => children(item, style, index),
    [children],
  );

  return (
    <Popover
      open={!!anchor || !!anchorPosition}
      anchorReference={anchor ? 'anchorEl' : 'anchorPosition'}
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      slotProps={popoverSlotProps}
      onClose={onClose}
      {...popoverProps}
    >
      <SelectList
        items={items}
        width={width}
        enableSearch={enableSearch}
        enableVirtualization={enableVirtualization}
        searchString={searchString}
        onSearch={handleSearch}
      >
        {renderChildren}
      </SelectList>
    </Popover>
  );
};

export default SelectMenu;
