/**
 * Legacy category IDs that have been replaced by newer ones
 */
export enum DeprecatedElementCategoryID {
  /**
   * Bjälklag, botten
   */
  FramingOfJoistsBottom = '2.1',

  /**
   * Bjälklag, mellan
   */
  FramingOfJoistsMiddle = '2.3',

  /**
   * Bjälklag, vinds
   */
  FramingOfJoistsAttic = '2.4',

  /**
   * Innerväggar, skiljande
   */
  InnerWallsParting = '3',

  /**
   * Innerväggar, ickeskiljande & bärande
   */
  InnerWallsNonPartingBearing = '3.1',

  /**
   * Innerväggar, lätta / ickebärande (non-bearing)
   */
  InnerWallsCurtain = '3.2',

  /**
   * Fasad & ytterverväggar
   */
  FacadeAndOuterWalls = '4',

  /**
   * Ytterverväggar
   */
  OuterWalls = '4.1',

  /**
   * Fasadbeläggning
   */
  FacadeCoating = '6',

  /**
   * Takbjälklag
   */
  RoofFramingOfJoists = '7.2',

  /**
   * Takbeläggning
   */
  RoofCovering = '8',

  /**
   * Trapp
   */
  Stairwell = '10',

  /**
   * Ytterdörr
   */
  DoorOuter = '15',

  /**
   * Innerdörr
   */
  DoorInner = '16',

  /**
   * Ytskikt
   */
  SurfaceLayer = '20',

  /**
   * Stomme
   */
  Framework = '1',

  /**
   * Bjälklag
   */
  FramingOfJoists = '2',

  /**
   * Bjälklag, källar
   */
  FramingOfJoistsCellar = '2.2',

  /**
   * Fasad
   */
  Facade = '5', //to be removed

  /**
   * Takunderbyggnad
   */
  RoofSubstructure = '7.1',

  /**
   * Hissschakt
   */
  ElevatorShaft = '9',

  /**
   * Stomkomplettering
   */
  FrameworkCompletion = '21',

  Installations = '19',
  Floors = '25',
  //   MiddleFloorSlab = '2.3',
  FloorSurface = '26b',
  //   Stairs = '10',
  CeilingSurface = '28',
  //   Facades = '4',
  //   ExternalWall = '4.1',
  Window = '14',
  //   Door = '15',
  Balcony = '11',
  //   FacadeCladding = '6',
  Roof = '7',
  //   AtticSlab = '2.4',
  //   RoofSlab = '7.2',
  //   RoofCladding = '8',
  InternalWalls = '37',
  //   PartingWall = '3',
  //   NonPartingLoadBearingInternalWall = '3.1',
  //   CurtainWall = '3.2',
  //   InternalWallSurface = '20',
  Foundation = '17',
  Piling = '18',
  //   GroundFloorSlab = '2.1',
  GroundWall = '45',
}
