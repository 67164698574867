import { IElementCategory } from '../../models/element_categories.interface';
import { ceramics } from './ceramics';
import { concrete } from './concrete';
import { gypsum } from './gypsum';
import { insulation } from './insulation';
import { metal } from './metal';
import { windowsDoors } from './windows_doors';
import { wood } from './wood';
import { labour } from './labour';
import { energy } from './energy';
import { otherProductCategory } from './other-product';

// Will appear in order in UI
export const productCategories: IElementCategory[] = [
  ceramics,
  concrete,
  gypsum,
  insulation,
  metal,
  windowsDoors,
  wood,
  otherProductCategory,
  energy,
  labour,
];
