import { useMemo, useState } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import { isDefined } from '../../../shared/helpers/array_helpers';

interface UseSearchProps<T> {
  items: T[];
  externalSearch?: string;
  options?: IFuseOptions<T>;
}

export const useSearch = <T>({
  items,
  externalSearch,
  options,
}: UseSearchProps<T>) => {
  const [localSearch, setLocalSearch] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(items, {
        includeScore: true,
        shouldSort: false,
        minMatchCharLength: 1,
        threshold: 0.2,
        distance: 1000, // Distance * threshold is the amount of characters searched
        keys: ['id', 'name', 'value', 'label'],
        ...options,
      }),
    [items, options],
  );

  const filteredItems = useMemo(() => {
    const searchString = externalSearch ?? localSearch;

    const filtered = fuse
      .search(searchString)
      .map((foundItem) => items[foundItem.refIndex])
      .filter(isDefined);

    return searchString ? filtered : items;
  }, [fuse, items, localSearch, externalSearch]);

  return useMemo(
    () => ({
      searchString: localSearch,
      setSearchString: setLocalSearch,
      filteredItems,
    }),
    [localSearch, filteredItems],
  );
};
