import React, { SyntheticEvent, useCallback } from 'react';
import { Tabs, Tab } from '@mui/material';

export type RecipeSelectorTab = 'owner' | 'team' | 'public';

interface RecipeSelectorTabsProps {
  selectedTab: RecipeSelectorTab | undefined;
  onChange: (tab?: RecipeSelectorTab) => void;
}

const RecipeSelectorTabs: React.FC<RecipeSelectorTabsProps> = ({
  selectedTab,
  onChange,
}) => {
  const selectTab = useCallback(
    (event: SyntheticEvent, tab: RecipeSelectorTab) => {
      onChange(tab);
    },
    [onChange],
  );

  const deselectTab = useCallback(
    (tab: RecipeSelectorTab) => () => {
      if (selectedTab === tab) {
        onChange(undefined);
      }
    },
    [onChange, selectedTab],
  );

  return (
    <Tabs
      textColor="secondary"
      value={selectedTab ?? false}
      onChange={selectTab}
      TabIndicatorProps={tabIndicatorStyles}
    >
      <Tab label="Created by Me" value="owner" onClick={deselectTab('owner')} />
      <Tab label="Team Recipes" value="team" onClick={deselectTab('team')} />
      <Tab
        label="Public Recipes"
        value="public"
        onClick={deselectTab('public')}
      />
    </Tabs>
  );
};

const tabIndicatorStyles = { sx: { display: 'none' } } as const;

export default RecipeSelectorTabs;
