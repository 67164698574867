import { GridType } from './grid-type.interface';
import {
  FactoryCountExpression,
  IFactoryProductElement,
} from './element_factory_helpers.interface';
import {
  ElementPropertyName,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryProperty,
} from './element_property.interface';
import {
  FactoryQuantityRecord,
  IFactoryQuantityProperty,
} from './element_quantities.interface';
import { IElement } from './project.interface';
import { Replace, RequireProperties } from './type_helpers.interface';
import { Recipe } from './recipe.interface';
import { Results, QuantityUnit } from './unit.interface';
import { DeprecatedElementCategoryID } from './deprecated-element-categories.interface';
import { ProductID } from './product.interface';
/**
 * Unique ids for element categories.
 * Note: new ids might be added, NEVER CHANGED!
 */
export enum ElementCategoryID {
  // Deprecated categories - using DeprecatedElementCategoryID enum values
  Framework = DeprecatedElementCategoryID.Framework,
  FramingOfJoists = DeprecatedElementCategoryID.FramingOfJoists,
  FramingOfJoistsBottom = DeprecatedElementCategoryID.FramingOfJoistsBottom,
  FramingOfJoistsCellar = DeprecatedElementCategoryID.FramingOfJoistsCellar,
  FramingOfJoistsMiddle = DeprecatedElementCategoryID.FramingOfJoistsMiddle,
  FramingOfJoistsAttic = DeprecatedElementCategoryID.FramingOfJoistsAttic,
  InnerWallsParting = DeprecatedElementCategoryID.InnerWallsParting,
  InnerWallsNonPartingBearing = DeprecatedElementCategoryID.InnerWallsNonPartingBearing,
  InnerWallsCurtain = DeprecatedElementCategoryID.InnerWallsCurtain,
  FacadeAndOuterWalls = DeprecatedElementCategoryID.FacadeAndOuterWalls,
  OuterWalls = DeprecatedElementCategoryID.OuterWalls,
  Facade = DeprecatedElementCategoryID.Facade,
  FacadeCoating = DeprecatedElementCategoryID.FacadeCoating,
  RoofFramingOfJoists = DeprecatedElementCategoryID.RoofFramingOfJoists,
  RoofSubstructure = DeprecatedElementCategoryID.RoofSubstructure,
  RoofCovering = DeprecatedElementCategoryID.RoofCovering,
  ElevatorShaft = DeprecatedElementCategoryID.ElevatorShaft,
  Stairwell = DeprecatedElementCategoryID.Stairwell,
  DoorOuter = DeprecatedElementCategoryID.DoorOuter,
  DoorInner = DeprecatedElementCategoryID.DoorInner,
  SurfaceLayer = DeprecatedElementCategoryID.SurfaceLayer,
  FrameworkCompletion = DeprecatedElementCategoryID.FrameworkCompletion,
  Installations = DeprecatedElementCategoryID.Installations,
  Floors = DeprecatedElementCategoryID.Floors,
  FloorSurface = DeprecatedElementCategoryID.FloorSurface,
  CeilingSurface = DeprecatedElementCategoryID.CeilingSurface,
  Window = DeprecatedElementCategoryID.Window,
  Balcony = DeprecatedElementCategoryID.Balcony,
  Roof = DeprecatedElementCategoryID.Roof,
  InternalWalls = DeprecatedElementCategoryID.InternalWalls,
  Foundation = DeprecatedElementCategoryID.Foundation,
  Piling = DeprecatedElementCategoryID.Piling,
  GroundWall = DeprecatedElementCategoryID.GroundWall,

  /**
   * Empty, not selected
   */
  None = '',

  // Product categories
  Concrete = '22',
  Wood = '46',
  Insulation = '47',
  Metal = '48',
  Gypsum = '49',
  Ceramics = '50',
  WindowsDoors = '53',
  Labour = '60',
  Energy = '61',
  Ventilation = '62',
  Heating = '63',
  OtherProduct = 'other-product',

  // System categories
  Column = '13',
  Beam = '12',
  Wall = '51',
  Slab = '52',
  Maintenance = '64',
  Other = 'other',

  // Main categories
  ROOF = '54',
  FACADES = '55',
  FOUNDATION = '56',
  FLOORS = '57',
  INTERNAL_WALLS = '58',
  INSTALLATIONS = '59',
  MAIN_OTHER = 'main-other',
}

/**
 * Main categories in order of appearance in the UI
 */
export const mainCategoryIds = [
  ElementCategoryID.FACADES,
  ElementCategoryID.ROOF,
  ElementCategoryID.INTERNAL_WALLS,
  ElementCategoryID.FLOORS,
  ElementCategoryID.FOUNDATION,
  ElementCategoryID.INSTALLATIONS,
  ElementCategoryID.MAIN_OTHER,
] as const;

export type MainCategoryId = (typeof mainCategoryIds)[number];
export type IMainCategoryElement = Replace<
  IElement,
  { category_id: MainCategoryId }
>;

export const systemCategoryIds = [
  ElementCategoryID.Column,
  ElementCategoryID.Beam,
  ElementCategoryID.Wall,
  ElementCategoryID.Slab,
  ElementCategoryID.Maintenance,
  ElementCategoryID.Other,
] as const;

export type SystemCategoryId = (typeof systemCategoryIds)[number];

export const productCategoryIds = [
  ElementCategoryID.Concrete,
  ElementCategoryID.Wood,
  ElementCategoryID.Insulation,
  ElementCategoryID.Metal,
  ElementCategoryID.Gypsum,
  ElementCategoryID.Ceramics,
  ElementCategoryID.WindowsDoors,
  ElementCategoryID.Labour,
  ElementCategoryID.OtherProduct,
  ElementCategoryID.Energy,
] as const;

export type ProductCategoryId = (typeof productCategoryIds)[number];

export const serviceCategoryIds = [
  ElementCategoryID.Ventilation,
  ElementCategoryID.Heating,
] as const;

export type ServiceCategoryId = (typeof serviceCategoryIds)[number];

export type IElementCategoryQuantityPropertiesFn = (
  element?: IElement,
) => FactoryQuantityRecord | IFactoryQuantityProperty[];

export type IElementCategoryElementPropertiesFn = (
  element: IElement,
) => IFactoryProperty[];

export type IElementCategoryElementsFn = (
  element: IElement,
) => IFactoryProductElement[];

export type IElementCategoryPropertySelectionByProductIdFn = (
  productId: ProductID,
) => {
  [key: string]:
    | IElementSelectProperty['count']
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | IElementSwitchProperty['count'];
};

export interface PropertiesOptions {
  [key: string]: {
    property: IFactoryProperty;
    propertyName: string;
    propertyType?: string;
  };
}

export interface IElementCategory {
  id: ElementCategoryID;
  parent_id?: ElementCategoryID;
  name: string;
  gridType?: GridType;

  /**
   * Optional list of properties to add to elements using this category
   */
  properties?: IFactoryProperty[];

  /**
   * Pass true to hide category from the list
   */
  disabled?: boolean;

  /**
   * Optional default count for elements using this category
   */
  defaultCount?: FactoryCountExpression;

  /**
   * Optional default unit for elements using this category
   */
  defaultUnit?: QuantityUnit;

  /**
   * Display color for the advanced product that belongs to this category
   */
  color?: string;

  /**
   * Optional list of mutual properties that should not be added to elements using this category
   */
  excludeMutualProperties?: ElementPropertyName[];

  /**
   * Optional list of product IDs that are available for this category
   */
  availableProductIds?: string[];

  /**
   * Optional function to make category control chilren of the element
   */
  getChildElements?: IElementCategoryElementsFn;

  /**
   * Optional function to make category control element properties.
   * Will override properties property
   */
  getElementProperties?: IElementCategoryElementPropertiesFn;

  /**
   * Optional function to make category control quantity properties
   */
  getQuantityProperties?: IElementCategoryQuantityPropertiesFn;

  /**
   * Tell werther the recipe should be listed in the category or not
   * @param recipe
   * @param element
   */
  recipeFilter?: (recipe: Recipe, element: IElement) => boolean;

  /**
   * Get the selection required to get a certain product from the product tree.
   */
  getElementPropertySelectionByProductId?: IElementCategoryPropertySelectionByProductIdFn;
}

export type IElementCategoryWithCalculatedProperties = RequireProperties<
  IElementCategory,
  'getElementProperties'
>;
export type IElementCategoryWithQuantityProperties = RequireProperties<
  IElementCategory,
  'getQuantityProperties'
>;

export type IElementCategoryWithAutoElements = RequireProperties<
  IElementCategory,
  'getChildElements'
>;

export type ElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, Results>
>;

export type MainElementCategoryConversionFactorRecord = Partial<
  Record<ElementCategoryID, ElementCategoryConversionFactorRecord>
>;
