import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import TextMenu from '../../../../menus/TextMenu/TextMenu';
import { IElement } from '../../../../../../../shared/models/project.interface';
import {
  getElementProperty,
  getSelectPropertyOptions,
} from '../../../../../../../shared/helpers/element_property_helpers';
import {
  IElementSelectProperty,
  ElementPropertyName,
  IElementPropertyOption,
} from '../../../../../../../shared/models/element_property.interface';
import { SelectListChildren } from '../../../../menus/menu.model';
import SelectMenuItem from '../../../../menus/ListItems/SelectMenuItem';
import { TypographyProps } from '@mui/material';

interface RecipeSelectorSBEFMenuProps {
  element: IElement;
  selectedOption: string;
  onSelect: (option: string) => void;
}

const RecipeSelectorSBEFMenu: React.FC<RecipeSelectorSBEFMenuProps> = ({
  element,
  selectedOption,
  onSelect,
}) => {
  const [anchor, setAnchor] = useState<Element>();
  const [disableDivider, setDisableDivider] = useState(false);

  const sbefOptions = useMemo(() => {
    const sbefProperty = getElementProperty<
      IElement,
      false,
      IElementSelectProperty
    >(element, ElementPropertyName.SBEFCode);

    return getSelectPropertyOptions(sbefProperty);
  }, [element]);

  const handleSelect = useCallback(
    ({
      currentTarget: {
        dataset: { item },
      },
    }: MouseEvent<HTMLDivElement>) => {
      if (item) {
        onSelect(item === 'none' ? '' : item);
        setAnchor(undefined);
      }
    },
    [onSelect],
  );

  const handleCloseMenu = useCallback(() => {
    setAnchor(undefined);
  }, [setAnchor]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setDisableDivider(searchString.length > 0);
    },
    [setDisableDivider],
  );

  const renderOptions = useCallback<SelectListChildren<IElementPropertyOption>>(
    ({ value }, style) => {
      const sbefValue = value as string;
      return (
        <SelectMenuItem
          indentAfterFirstWord
          style={style}
          value={sbefValue}
          showTooltip={sbefValue.length > 40}
          disableDivider={disableDivider}
          selected={sbefValue === selectedOption}
          onClick={handleSelect}
        />
      );
    },
    [handleSelect, selectedOption, disableDivider],
  );

  const buttonLabelProps = useMemo<TypographyProps>(() => {
    return {
      textTransform: 'uppercase',
      color: 'neutral.main',
      fontWeight: 500,
      maxWidth: 500,
    };
  }, []);

  return (
    <TextMenu
      menuWidth="medium"
      items={sbefOptions}
      anchor={anchor}
      label={selectedOption}
      fallbackLabel="SBEF Code"
      buttonLabelProps={buttonLabelProps}
      onOpen={setAnchor}
      onClose={handleCloseMenu}
      onSearch={handleSearch}
    >
      {renderOptions}
    </TextMenu>
  );
};

export default RecipeSelectorSBEFMenu;
