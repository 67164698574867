import { IFactoryProductElement } from '../../../models/element_factory_helpers.interface';
import { createElementProperty } from '../../../helpers/element_property_factory_helpers';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';
import {
  IElementCategory,
  ElementCategoryID,
  IElementCategoryElementPropertiesFn,
  IElementCategoryElementsFn,
  IElementCategoryPropertySelectionByProductIdFn,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyResolvedCounts,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import { ProductID } from '../../../models/product.interface';
import { ElementKind } from '../../../models/project.interface';
import {
  concreteTypeClimateImprovedRecord,
  concreteQualityClimateImprovedLevel1Record,
  concreteQualityClimateImprovedLevel2Record,
  concreteQualityClimateImprovedLevel3Record,
  concreteQualityClimateImprovedLevel4Record,
  concreteTypeRecord,
  ConcretePropertyName,
  ConcreteQuality,
  concreteQualityRecord,
  ConcreteType,
  reinforcementSteelProductId,
  reinforcementSteelStainlessProductId,
  concreteTypeProperty,
  climateImprovedProperty,
  ClimateImprovedLevels,
  climateImprovedLevelProperty,
  concreteQualityProperty,
  stainlessReinforcementProperty,
  reinforcementSteelAmountProperty,
  concreteQualityOldClimateImprovedRecord,
} from './concrete.model';
import {
  reusedContentProperty,
  ProductCategoryPropertyName,
  reusedContentFactoryElement,
} from '../processor.model';
import {
  DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';
import { findKey } from 'lodash';
import { required } from '../../../helpers/function_helpers';

const allProperties = [
  concreteTypeProperty,
  climateImprovedProperty,
  climateImprovedLevelProperty,
  concreteQualityProperty,
  stainlessReinforcementProperty,
  reinforcementSteelAmountProperty,
  reusedContentProperty,
];

const getProductIdFromConcreteQuality = (
  quality: ConcreteQuality | string,
  climateImprovedLevel?: string,
): ProductID => {
  let record = concreteQualityRecord;

  switch (climateImprovedLevel) {
    case ClimateImprovedLevels.Level_1:
      record = concreteQualityClimateImprovedLevel1Record;
      break;
    case ClimateImprovedLevels.Level_2:
      record = concreteQualityClimateImprovedLevel2Record;
      break;
    case ClimateImprovedLevels.Level_3:
      record = concreteQualityClimateImprovedLevel3Record;
      break;
    case ClimateImprovedLevels.Level_4:
      record = concreteQualityClimateImprovedLevel4Record;
      break;
  }

  const product_id = record[quality as ConcreteQuality];

  if (!product_id) {
    throw new Error(`Product id not found for quality ${'' + quality}`);
  }
  return product_id;
};

const getProductIdFromConcreteType = (
  type: ConcreteType | string,
  climateImproved?: boolean,
): ProductID => {
  const record = climateImproved
    ? concreteTypeClimateImprovedRecord
    : concreteTypeRecord;

  const product_id = record[type as ConcreteType];

  if (!product_id) {
    throw new Error(`Product id not found for type ${'' + type}`);
  }
  return product_id;
};

const getReinforcementSteelProductId = (stainless: boolean): ProductID =>
  stainless
    ? reinforcementSteelStainlessProductId
    : reinforcementSteelProductId;

const getElementProperties: IElementCategoryElementPropertiesFn = (element) => {
  // Short-hand to get property with default value
  const getValue = (
    type: ConcretePropertyName,
  ): ElementPropertyResolvedCounts =>
    getElementPropertyResolvedCountByNameOrId(element, type, allProperties);

  const properties: IFactoryProperty[] = [
    reusedContentProperty,
    concreteTypeProperty,
  ];

  // Use default value since it will not be set first time
  const type = getElementPropertyResolvedCountByNameOrId(
    element,
    ConcretePropertyName.ConcreteType,
    allProperties,
  );

  if (type === ConcreteType.CastInPlace) {
    const reinforcementSteelAmount = getValue(
      ConcretePropertyName.ReinforcementSteel,
    );
    properties.push(
      climateImprovedLevelProperty,
      concreteQualityProperty,
      reinforcementSteelAmountProperty,
    );

    // Only add stainless steel property if there is any amount of steel
    if (reinforcementSteelAmount && +reinforcementSteelAmount > 0) {
      properties.push(stainlessReinforcementProperty);
    }
  } else {
    properties.push(climateImprovedProperty);
  }

  return properties.map((p) => createElementProperty(p));
};

const getChildElements: IElementCategoryElementsFn = (element) => {
  const elements: IFactoryProductElement[] = [];

  // Short-hand to get property with default value
  const getValue = (
    type: ConcretePropertyName | ProductCategoryPropertyName,
  ): ElementPropertyResolvedCounts =>
    getElementPropertyResolvedCountByNameOrId(element, type, allProperties);

  const type = getValue(ConcretePropertyName.ConcreteType) as ConcreteType;
  const climateImproved = getValue(ConcretePropertyName.ClimateImproved);
  const climateImprovedLevel = getValue(
    ConcretePropertyName.ClimateImprovedLevel,
  );

  if (typeof type !== 'string') {
    throw new Error('ConcreteType is not a string');
  }
  if (typeof climateImproved !== 'boolean') {
    throw new Error('Climate improved is not a boolean');
  }
  if (typeof climateImprovedLevel !== 'string') {
    throw new Error('Climate improved Level is not a string');
  }

  if (type === ConcreteType.CastInPlace) {
    const quality = getValue(ConcretePropertyName.ConcreteQuality);
    const stainlessReinforcement = getValue(
      ConcretePropertyName.StainlessReinforcement,
    );

    if (typeof quality !== 'string') {
      throw new Error('ConcreteQuality is not a string');
    }
    if (typeof stainlessReinforcement !== 'boolean') {
      throw new Error('Climate improved is not a boolean');
    }

    const concrete_id = getProductIdFromConcreteQuality(
      quality,
      climateImprovedLevel,
    );

    const steel_id = getReinforcementSteelProductId(stainlessReinforcement);

    // Add concrete product first to make sure it is the first product in the list
    elements.push({
      kind: ElementKind.Product,
      product_id: concrete_id,
      count: `(1 - ${
        ProductCategoryPropertyName.ReusedContent
      }) * max(0, mass - ${ConcretePropertyName.ReinforcementSteel} * volume)`,
      unit: 'kg',
    });

    // Always add steel since list will hide it if 0
    elements.push({
      kind: ElementKind.Product,
      product_id: steel_id,
      count: `(1 - ${
        ProductCategoryPropertyName.ReusedContent
      }) * volume * ${ConcretePropertyName.ReinforcementSteel}`,
      unit: 'kg',
      hide_product_without_count: true,
    });
  } else {
    const product_id = getProductIdFromConcreteType(type, climateImproved);
    elements.push({
      kind: ElementKind.Product,
      product_id,
      count: `(1 - ${ProductCategoryPropertyName.ReusedContent}) * mass`,
      unit: 'kg',
    });
  }
  // Add reused content last for density to work
  elements.push(reusedContentFactoryElement);

  return elements;
};

const readyMixConreteDensity = 'densityArray[1]';
const steelRebarDensity = 'densityArray[2]';
export const castInPlaceDensity = `${ConcretePropertyName.ReinforcementSteel} * (1 - ${readyMixConreteDensity}/${steelRebarDensity}) + ${readyMixConreteDensity}`;
export const otherTypeDesity = `densityArray[1]`;
export const concreteDensity = `${ConcretePropertyName.ConcreteType} == "${ConcreteType.CastInPlace}" ? ${castInPlaceDensity} : ${otherTypeDesity}`;

const castInPlaceProductIds = [
  ...Object.values(concreteQualityRecord),
  ...Object.values(concreteQualityClimateImprovedLevel1Record),
  ...Object.values(concreteQualityClimateImprovedLevel2Record),
  ...Object.values(concreteQualityClimateImprovedLevel3Record),
  ...Object.values(concreteQualityClimateImprovedLevel4Record),
  // Old climate improved product
  ...Object.values(concreteQualityOldClimateImprovedRecord),
];

export const concrete: IElementCategory = {
  ...DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  id: ElementCategoryID.Concrete,
  name: 'Concrete',
  color: '#91A6CE',
  availableProductIds: [
    ...Object.values(concreteTypeRecord),
    ...Object.values(concreteTypeClimateImprovedRecord),
    ...castInPlaceProductIds,
  ],
  getElementPropertySelectionByProductId: (productId) => {
    const options: ReturnType<IElementCategoryPropertySelectionByProductIdFn> =
      {};

    // Old climate improved

    // Cast in place products
    if (castInPlaceProductIds.includes(productId)) {
      options[ConcretePropertyName.ClimateImprovedLevel] =
        getClimateImprovedLevel(productId);
      options[ConcretePropertyName.ConcreteType] = ConcreteType.CastInPlace;
      options[ConcretePropertyName.ConcreteQuality] = required(
        findKey(concreteQualityRecord, (id) => id === productId) ??
          findKey(
            concreteQualityClimateImprovedLevel1Record,
            (id) => id === productId,
          ) ??
          findKey(
            concreteQualityClimateImprovedLevel2Record,
            (id) => id === productId,
          ) ??
          findKey(
            concreteQualityClimateImprovedLevel3Record,
            (id) => id === productId,
          ) ??
          findKey(
            concreteQualityClimateImprovedLevel4Record,
            (id) => id === productId,
          ) ??
          findKey(
            concreteQualityOldClimateImprovedRecord,
            (id) => id === productId,
          ),
      );
    }
    // All other products
    else {
      options[ConcretePropertyName.ConcreteType] =
        findKey(concreteTypeRecord, (id) => id === productId) ??
        findKey(concreteTypeClimateImprovedRecord, (id) => id === productId);
      options[ConcretePropertyName.ClimateImproved] = Object.values(
        concreteTypeClimateImprovedRecord,
      ).includes(productId);
    }

    return options;
  },
  getChildElements,
  getQuantityProperties: () => {
    return {
      ...DEFAULT_QUANTITY_PROPERTIES,
      density: {
        fallbackCount: concreteDensity,
      },
    };
  },
  getElementProperties,
};

const getClimateImprovedLevel = (
  productId: ProductID,
): ClimateImprovedLevels => {
  if (productId === 'boverket_sv-SE_6000000031') {
    return ClimateImprovedLevels.Level_2;
  }
  const level1 = Object.values(
    concreteQualityClimateImprovedLevel1Record,
  ).includes(productId);
  const level2 = Object.values(
    concreteQualityClimateImprovedLevel2Record,
  ).includes(productId);
  const level3 = Object.values(
    concreteQualityClimateImprovedLevel3Record,
  ).includes(productId);
  const level4 = Object.values(
    concreteQualityClimateImprovedLevel4Record,
  ).includes(productId);
  const oldLevel = Object.values(
    concreteQualityOldClimateImprovedRecord,
  ).includes(productId);

  if (oldLevel) {
    return ClimateImprovedLevels.Level_2;
  }
  if (level1) {
    return ClimateImprovedLevels.Level_1;
  }
  if (level2) {
    return ClimateImprovedLevels.Level_2;
  }
  if (level3) {
    return ClimateImprovedLevels.Level_3;
  }
  if (level4) {
    return ClimateImprovedLevels.Level_4;
  }
  return ClimateImprovedLevels.None;
};
