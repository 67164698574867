import { isEqual } from 'lodash';
import {
  getElementQuantityFormattedName,
  getElementQuantityProperties,
  isElementQuantityName,
  isElementQuantityRecord,
  updateElementQuantityAndPropertiesInRoot,
} from './element_quantity_helpers';
import {
  ElementPropertyCountType,
  ElementPropertyResolvedCount,
  ElementPropertyResolvedCounts,
  ElementPropertyInputType,
  ElementPropertyName,
  ElementPropertyOptionsKey,
  ElementPropertySource,
  ElementPropertySourceID,
  ElementSelectPropertyCountType,
  IElementExpressionProperty,
  IElementProperty,
  IElementPropertyFactoryOption,
  IElementPropertyOption,
  IElementPropertyParent,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryExpressionProperty,
  IFactoryProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
  PropertyResolvedCountRecord,
} from '../models/element_property.interface';
import {
  elementQuantityNames,
  ElementQuantityRecord,
  IconQuantityName,
  iconQuantityNames,
  IElementQuantityExpressionProperty,
  IElementQuantityProperty,
} from '../models/element_quantities.interface';
import {
  IBuildingVersion,
  IElement,
  IProductElement,
  OneOfElements,
  OneOfParentElements,
  OneOfPropertyElements,
  Project,
} from '../models/project.interface';
import shallowEqual, {
  EMPTY_ARRAY,
  cloneReplaceById,
  findMap,
  getItemByIdOrName,
  isDefined,
  isOneOf,
  removeItems,
} from './array_helpers';
import { getElementCategoryId } from './element_category_helpers';
import {
  addElementProperties,
  applyIdAndSourceToProperty,
  createElementProperty,
  createPropertySelectOptions,
  labelFromValue,
  toSwitchCount,
} from './element_property_factory_helpers';
import {
  createExpression,
  ExpressionValueFactoryType,
  isExpressionValue,
  isExpressionValueFactoryType,
} from './expression_factory_helpers';
import {
  getExpression,
  isEqualExpressionValues,
} from './expression_solving_helpers';
import {
  ExpressionVariables,
  ExpressionVariablesRecord,
  formatToExpressionVariable,
  omitDefaultVariables,
} from './expression_variables_helpers';
import { clamp, getValueAsNumber } from './math_helpers';
import {
  applyChanges,
  getId,
  hasDefinedProperties,
  isNonArrayObject,
  isPrimitive,
  omit,
  pick,
} from './object_helpers';
import { updateElements } from './project_helpers';
import {
  getRecipeId,
  isNodonRecipeProperty,
  resolveAutoRecipeId,
} from './recipe_helpers';
import {
  ElementPath,
  flattenElements,
  getElementById,
  isOneOfPropertyElements,
} from './recursive_element_helpers';
import {
  isValidSelectPropertyCount,
  isValidSwitchPropertyCount,
  validateElementProperties,
  validateProperty,
  validatePropertySource,
} from '../validation/element-property.validation';
import {
  ItemOrItemId,
  OptionallyRequired,
  OptionallyRequiredOptions,
  PartialRecord,
  RequireProperties,
} from '../models/type_helpers.interface';
import { convertName, findFreeName } from './string_helpers';
import {
  mutualProperties,
  SBEF_CODES,
} from '../templates/categories/categories-properties.model';
import { reusedContentProperty } from '../templates/categories/processor.model';
import { cacheFactory, required } from './function_helpers';

/**
 * Use this as default properties not cause rerenders of components
 */
const EMPTY_PROPERTIES: Readonly<Readonly<IElementProperty>[]> = Object.freeze(
  [],
);

const ELEMENT_PROPERTY_INPUT_TYPES = Object.values(ElementPropertyInputType);

export type IPropertiesOrPropertyParent =
  | IElementProperty[]
  | IElementPropertyParent
  | ElementQuantityRecord;

type ElementWithProperties = Pick<OneOfPropertyElements, 'properties'>;

// Require name and id to be able to update a property (but keep smartness around different types of count)
export type PropertyChange =
  | RequireProperties<IFactoryExpressionProperty, 'id'>
  | RequireProperties<IFactorySelectProperty, 'id'>
  | RequireProperties<IFactorySwitchProperty, 'id'>
  | RequireProperties<IFactoryExpressionProperty, 'name'>
  | RequireProperties<IFactorySelectProperty, 'name'>
  | RequireProperties<IFactorySwitchProperty, 'name'>;

interface PropertyChangeRecord {
  [name: string]: Partial<PropertyChange> | PropertyChange['count'] | undefined;
}

/**
 * Convert a property change record to an array of property changes
 * @param recordOrArray
 * @returns
 */
export const propertyChangeRecordToArray = (
  recordOrArray: PropertyChangeRecord | (PropertyChange | undefined)[],
): PropertyChange[] => {
  return Array.isArray(recordOrArray)
    ? recordOrArray.filter(isDefined)
    : Object.entries(recordOrArray)
        .map(([name, change]) => {
          if (change === undefined) {
            return;
          }
          if (isPrimitive(change)) {
            return { name, count: change } as PropertyChange;
          }
          return { name, ...change } as PropertyChange;
        })
        .filter(isDefined);
};

/**
 * Update elements with one or more changes.
 * Note. Passing undefined properties will update the property with an undefined value.
 * @param root A building version or project that will be returned updated
 * @param elementChanges
 * @returns
 */
export const updateElementProperties = <
  R extends OneOfParentElements | Project,
  T extends PropertyChange,
>(
  root: R,
  elementOrId: ItemOrItemId<OneOfPropertyElements>,
  ...propertyChanges: (T | undefined)[]
): R => {
  const changes = propertyChanges.filter(isDefined);
  if (!propertyChanges.length) {
    return root;
  }
  const elementId = getId(elementOrId);

  // Make sure to fetch the latest instance to not overwrite any changes made
  const element = getElementById(root, elementId);
  const properties = getElementProperties(element);

  if (!isOneOfPropertyElements(element)) {
    throw new Error(`Element not found with id ${elementId}`);
  }

  const updatedProperties = properties.map((property) => {
    const change = getItemByIdOrName(changes, property, false);
    if (!change) {
      return property;
    }
    // Remove item from changes so we can validate that we made all changes
    removeItems(changes, change);

    return validateProperty(
      createElementProperty(
        { ...property, ...omit(change, 'id', 'type') }, // Can't change id or type
        undefined,
        false,
      ),
    );
  });

  if (changes.length) {
    throw new Error('Could not find all properties to update');
  }

  // Update element if properties have changed else return project unmodified
  if (!shallowEqual(updatedProperties, properties)) {
    return updateElements(root, {
      id: elementId,
      properties: updatedProperties,
    } as IElement);
  }
  return root;
};

/**
 * Update all element properties and quantity properties in a version based on the VariablesRecord
 * @param root A building version or project that will be returned updated
 * @param version
 * @param record Record ({ [element.id]: variables }) with everything to update. Smaller record = less computation
 * @returns Modified project
 */
export const updateElementPropertiesFromVariablesRecord = <
  R extends IBuildingVersion | Project,
>(
  root: R,
  record: ExpressionVariablesRecord,
): R => {
  const ids = flattenElements(root).map((e) => e.id);
  for (const id of ids) {
    const variables = record[id];
    if (!variables) {
      console.warn(`No variables found for element ${id}`);
    } else {
      root = updateElementPropertiesFromVariables(root, id, variables);
    }
  }
  return root;
};

/**
 * Update all element properties and quantity properties in a version based on the VariablesRecord
 * @param project
 * @param version
 * @param record Record ({ [element.id]: variables }) with everything to update. Smaller record = less computation
 * @returns Modified project
 */
export const updateElementPropertiesFromVariables = <
  R extends IBuildingVersion | Project,
>(
  root: R,
  elementOrId: ItemOrItemId<OneOfElements>,
  variables: ExpressionVariables,
): R => {
  const nonDefaultKeys = Object.keys(omitDefaultVariables(variables));
  // Version & Element will be a new version as soon as any property is updated (since project is immutable)
  const element =
    typeof elementOrId === 'string'
      ? getElementById(root, elementOrId, true)
      : elementOrId;
  const properties = getElementAndQuantityProperties(element);

  if (
    !isOneOfPropertyElements(element) ||
    !properties.length ||
    !nonDefaultKeys.length
  ) {
    return root;
  }

  const changes: PropertyChange[] = properties
    .map((property) => {
      const key = formatToExpressionVariable(property.name);
      const value = variables[key];

      // Base for any update
      const base = pick(property, 'id', 'name');

      // Skip if undefined
      if (value === undefined && !property.inheritFallback) {
        return;
      }
      const countKey = hasCount(property) ? 'count' : 'fallbackCount';

      if (isElementExpressionProperty(property)) {
        const resolved = (typeof value === 'number' && value) || 0;
        // Get either count or fallbackCount
        const count = getPropertyCount(property);

        // Only update if resolved value has changed since expression is not updated by this
        if (count.resolved === resolved) {
          return;
        }

        return {
          ...base,
          [countKey]: createExpression({
            resolved,
            expression: count.expression,
          }),
        };
      } else if (isElementSelectProperty(property)) {
        let fallbackCount = value as IElementSelectProperty['count'];

        // First check if we can inherit value from variables
        if (!canInheritFallbackCount(property, fallbackCount)) {
          const option = getSelectPropertyOptions(property)[0];

          // Else take the first option
          fallbackCount = option && getSelectPropertyOptionValue(option);

          // If still not allowed, don't do any changes
          if (!canInheritFallbackCount(property, fallbackCount)) {
            return;
          }
        }

        // If count is defined or inherited values is not defined in options we don't need to update
        if (!isValidSelectPropertyCount(fallbackCount)) {
          return;
        }
        const change: PropertyChange = {
          ...base,
          type: ElementPropertyInputType.Select,
          fallbackCount,
          inheritFallback: true,
        };
        return change;
      } else if (isElementSwitchProperty(property)) {
        if (
          !canInheritFallbackCount(property, value) ||
          !isValidSwitchPropertyCount(value)
        ) {
          return;
        }
        const change: PropertyChange = {
          ...base,
          type: ElementPropertyInputType.Switch, // Just for typing
          fallbackCount: value,
          inheritFallback: true,
        };
        return change;
      }
      return undefined;
    })
    .filter(isDefined);

  return updateElementQuantityAndPropertiesInRoot(root, element, ...changes);
};

/**
 * If a property can have an inherited fallback count or not
 * @param property
 * @returns
 */
const canInheritFallbackCount = (
  property: IElementProperty,
  newValue: unknown,
): boolean => {
  if (hasCount(property)) {
    return false;
  }
  // Select properties can only inherit fallback count if the new value is one of the options
  if (
    isElementSelectProperty(property) &&
    !isSelectPropertyOptionValue(property, newValue)
  ) {
    return false;
  }
  return !hasFallbackCount(property) || !!property.inheritFallback;
};

/**
 * Update a the value of an element property
 * @param elementOrProps
 * @param nameOrId
 * @param count
 * @returns
 */
export const setPropertyCountByNameOrId = <
  T extends IPropertiesOrPropertyParent,
>(
  elementOrProps: T,
  nameOrId: string,
  count: ExpressionValueFactoryType | ElementPropertyCountType | undefined,
): T => {
  const current = getElementProperty(elementOrProps, nameOrId, true);
  const updated = setPropertyCount(current, count);

  return replaceElementProperties(elementOrProps, updated as typeof current);
};

/**
 * Set multiple properties count values from a record of properties (name => count)
 * @param elementOrProps
 * @param countRecord
 * @param ignoreMissingProperties If true, properties that are not found will be ignored. If false, an error will be thrown.
 * @returns
 */
export const setPropertyCountsFromRecord = <
  T extends IPropertiesOrPropertyParent,
>(
  elementOrProps: T,
  countRecord?: PartialRecord<
    string,
    ExpressionValueFactoryType | ElementPropertyCountType | undefined
  >,
  ignoreMissingProperties = false,
): T => {
  for (const [name, count] of Object.entries(countRecord ?? {})) {
    if (ignoreMissingProperties && !getElementProperty(elementOrProps, name)) {
      continue;
    }
    elementOrProps = setPropertyCountByNameOrId(elementOrProps, name, count);
  }
  return elementOrProps;
};

export type SetPropertyCountMap = {
  [ElementPropertyInputType.Expression]: ExpressionValueFactoryType;
  [ElementPropertyInputType.Select]: IElementSelectProperty['count'];
  [ElementPropertyInputType.Switch]: IElementSwitchProperty['count'];
};

type SetPropertyCount<
  T extends keyof SetPropertyCountMap | undefined = undefined,
> = T extends string
  ? SetPropertyCountMap[T]
  : SetPropertyCountMap[ElementPropertyInputType.Expression];

/**
 * Set count value of a property
 * @param property Property to modify. If not changed
 * @param count New count value. For expression values count can be provided as a number or a string.
 * @returns
 */
export const setPropertyCount = <
  T extends IElementProperty | IElementQuantityProperty,
  D extends SetPropertyCount<T['type']>,
  R = D extends ElementPropertyCountType ? RequireProperties<T, 'count'> : T,
>(
  property: T,
  count: D | undefined,
): R => {
  if (count === undefined) {
    if (!hasFallbackCount(property)) {
      throw new Error(
        'Cannot set count to undefined for property without fallback count',
      );
    }

    // Remove count if it exist else leave property unmodified
    return (
      hasCount(property) ? { ...property, count: undefined } : property
    ) as R;
  }

  if (isElementExpressionProperty(property)) {
    return setExpressionPropertyCount(
      property,
      count as SetPropertyCount<ElementPropertyInputType.Expression>,
    ) as R;
  } else if (isElementSelectProperty(property)) {
    return setSelectPropertyCount(
      property,
      count as SetPropertyCount<ElementPropertyInputType.Select>,
    ) as R;
  } else if (isElementSwitchProperty(property)) {
    return setSwitchPropertyCount(
      property,
      count as SetPropertyCount<ElementPropertyInputType.Switch>,
    ) as R;
  }
  throw new Error('Unknown property type');
};

const setExpressionPropertyCount = (
  property: IElementExpressionProperty,
  countFactory: ExpressionValueFactoryType | IElementProperty['count'],
): IElementExpressionProperty => {
  if (countFactory === 'undefined') {
    return validateProperty({
      ...property,
      count: undefined,
    });
  }

  if (!isExpressionValueFactoryType(countFactory)) {
    throw new Error('Cannot set count to an invalid expression value');
  }

  // Make sure count is an expression
  const count = getExpression(countFactory);

  // Update if count is changed
  if (!isEqualExpressionValues(count, property.count, true)) {
    return validateProperty({
      ...property,
      count: getExpression(count),
    });
  }
  return property;
};

const setSelectPropertyCount = (
  property: IElementSelectProperty,
  count: IElementProperty['count'],
): IElementSelectProperty => {
  if (!isValidSelectPropertyCount(count)) {
    throw new Error('Cannot set count to an invalid select value');
  }
  if (count !== property.count) {
    return validateProperty({
      ...property,
      count,
    });
  }
  return property;
};

const setSwitchPropertyCount = (
  property: IElementSwitchProperty,
  count: IElementProperty['count'],
): IElementSwitchProperty => {
  if (!isValidSwitchPropertyCount(count)) {
    throw new Error('Cannot set count to an invalid switch value');
  }
  if (count !== property.count) {
    return validateProperty({
      ...property,
      count,
    });
  }
  return property;
};

/**
 * Get count OR fallbackCount from property
 * @param prop
 * @returns
 */
export const getPropertyCount = <
  T extends IElementProperty | IElement | IProductElement,
  R extends boolean = true,
>(
  prop: T,
  requireCount: R = true as R,
): OptionallyRequired<T['count'], R> =>
  required(prop.count ?? prop.fallbackCount, requireCount);

export const hasCount = <
  T extends IElementProperty | Partial<IFactoryProperty>,
>(
  prop?: T | RequireProperties<T, 'count'>,
): prop is RequireProperties<T, 'count'> => {
  return prop?.count !== undefined;
};

export const hasFallbackCount = <
  T extends IElementProperty | Partial<IFactoryProperty>,
>(
  prop?: T | RequireProperties<T, 'fallbackCount'>,
): prop is RequireProperties<T, 'fallbackCount'> => {
  return prop?.fallbackCount !== undefined;
};

/**
 * Return value from property.count. If count is an expression, return the resolved value.
 * @param property
 * @returns
 */
export const getElementPropertyResolvedCount = <
  T extends IElementProperty | IFactoryProperty,
  D extends ElementPropertyResolvedCount<T['type']> | undefined,
>(
  property: T | undefined,
  defaultValue?: D | undefined,
): D => {
  if (!property) {
    return defaultValue as D;
  }
  const { type } = property;

  // Expression type
  if (!type || type === ElementPropertyInputType.Expression) {
    // Typecheck needed for TS to know defaultValue is correct type
    if (defaultValue !== undefined && typeof defaultValue !== 'number') {
      throw new Error('Default value must be number');
    }

    return getExpressionPropertyResolvedCount(property, defaultValue) as D;
  }
  // Select type
  else if (type === ElementPropertyInputType.Select) {
    // Typecheck needed for TS to know defaultValue is correct type
    if (
      defaultValue !== undefined &&
      typeof defaultValue !== 'number' &&
      typeof defaultValue !== 'string'
    ) {
      throw new Error('Default value must be number for select');
    }

    return getSelectPropertyResolvedCount(property, defaultValue) as D;
  }
  // Switch type
  else if (type === ElementPropertyInputType.Switch) {
    // Typecheck needed for TS to know defaultValue is correct type
    if (defaultValue !== undefined && typeof defaultValue !== 'boolean') {
      throw new Error('Default value must be a boolean for switch type');
    }

    return getSwitchPropertyResolvedCount(property, defaultValue) as D;
  }
  throw new Error('Property type not supported');
};

const getExpressionPropertyResolvedCount = (
  property?: IElementExpressionProperty | IFactoryExpressionProperty,
  defaultValue = 0,
): number => {
  const count = property?.count ?? property?.fallbackCount;
  if (isExpressionValue(count)) {
    return count.resolved ?? defaultValue;
  }
  return getValueAsNumber(count, defaultValue);
};

/**
 * Get a select property count value. If count is undefined, return the first option value.
 * Will crash if no value can be returned
 * @param property
 * @param defaultValue
 * @returns
 */
export const getSelectPropertyResolvedCount = (
  property: IElementSelectProperty | IFactorySelectProperty,
  defaultValue?: ElementSelectPropertyCountType,
): ElementSelectPropertyCountType | undefined => {
  const { count, fallbackCount, inheritFallback } = property;

  const option = getSelectPropertyOptions(property)[0];
  const optionValue = option && getSelectPropertyOptionValue(option);

  // Get the first defined value in this order
  const value = [count, fallbackCount, defaultValue, optionValue]
    .filter(isDefined)
    .filter(isPrimitive)[0];

  if (value === undefined && !inheritFallback) {
    throw new Error('Select property count value is undefined');
  }
  return value;
};

/**
 * Test if a value is a valid option value for a select property.
 * I.E. That it's a string or a number that is included in the options array
 * @param prop
 * @param count
 * @returns
 */
export const isSelectPropertyOptionValue = (
  prop: IElementSelectProperty | IFactorySelectProperty,
  count: unknown,
): count is ElementSelectPropertyCountType => {
  if (
    typeof count === 'string' ||
    (typeof count === 'number' && isFinite(count))
  ) {
    const options =
      getSelectPropertyOptions(prop).map(getSelectPropertyOptionValue) ?? [];
    return options.includes(count);
  }
  return false;
};

const getSelectPropertyOptionsFromKey = (
  key: ElementPropertyOptionsKey,
): IElementPropertyOption[] =>
  // Cache to avoid recreating 100s of options for every element
  cacheFactory(() => {
    switch (key) {
      case ElementPropertyName.SBEFCode:
        return createPropertySelectOptions(SBEF_CODES);
      default:
        return [];
    }
  }, `getSelectPropertyOptionsFromKey["${key}"]`);

/**
 * Get options from a select property or an array of options or a key.
 * Use this to not have to store options in the property itself.
 * @param propOrOptions
 * @returns
 */
export const getSelectPropertyOptions = (
  prop: IElementSelectProperty | IFactorySelectProperty,
): IElementPropertyOption[] => {
  const options = prop.options;

  if (!options) {
    return EMPTY_ARRAY as IElementPropertyOption[];
  }

  if (typeof options === 'string') {
    return getSelectPropertyOptionsFromKey(options);
  }

  // Already valid options, don't make a new array
  if (options.every(isSelectPropertyOption)) {
    return options as IElementPropertyOption[];
  }

  // Create options from factory options
  return createPropertySelectOptions(options);
};

const isSelectPropertyOption = (
  option: IElementPropertyOption | IElementPropertyFactoryOption,
): option is IElementPropertyOption => {
  return (
    isNonArrayObject(option) && 'value' in option && isPrimitive(option.value)
  );
};

/**
 * Get a select property count value. If count is undefined, return the first option value.
 * Will crash if no value can be returned
 * @param property
 * @param defaultValue
 * @returns
 */
export const getSwitchPropertyResolvedCount = (
  { count, fallbackCount }: IElementSwitchProperty | IFactorySwitchProperty,
  defaultValue = false,
): boolean => toSwitchCount(count ?? fallbackCount) ?? defaultValue;

/**
 * Get value from select property option. If option is an object, return the value property.
 * @param option
 * @returns
 */
export const getSelectPropertyOptionValue = (
  option: IElementPropertyFactoryOption,
): ElementSelectPropertyCountType =>
  typeof option !== 'object' ? option : option.value;

/**
 * Get a property from a list of properties (or element) by name or id
 * @param elementOrProps
 * @param nameOrId
 * @param throwIfNotFound If true, will throw an error if property is not found
 * @returns
 */

export function getElementProperty<
  T extends IPropertiesOrPropertyParent | IElementProperty,
  E extends OptionallyRequiredOptions = false,
  R = OptionallyRequired<IElementProperty, E>,
>(
  elementOrProps: T | undefined,
  nameOrId?: IElementProperty['name'],
  throwIfNotFound: E = false as E,
): R {
  if (isElementProperty(elementOrProps)) {
    return elementOrProps as R;
  }
  const properties = getElementProperties(elementOrProps);
  return getItemByIdOrName(
    properties,
    nameOrId as string,
    throwIfNotFound
      ? `Property with name ${nameOrId as string} not found`
      : false,
  ) as R;
}

type GetResolvedCountDefault =
  | ElementPropertyResolvedCounts
  | IFactoryProperty[]
  | undefined;
type GetResolvedCountReturnValue<D extends GetResolvedCountDefault> =
  D extends undefined
    ? ElementPropertyResolvedCounts | undefined
    : D extends IFactoryProperty[]
      ? ElementPropertyResolvedCounts
      : D;

/**
 * Get a property count value (primitive) from a list of properties or element by name
 * @param elementOrProps
 * @param nameOrId
 * @param defaultValue Either a fixed value or a list of properties that must contain a property with the same name as requested
 * @returns
 */
export const getElementPropertyResolvedCountByNameOrId = <
  D extends GetResolvedCountDefault,
  R = GetResolvedCountReturnValue<D>,
>(
  elementOrProps: IPropertiesOrPropertyParent | undefined,
  nameOrId: IElementProperty['name'],
  defaultValue?: D,
): R => {
  const property = getElementProperty(elementOrProps, nameOrId);
  // Support to pass a list of properties as default value
  if (Array.isArray(defaultValue)) {
    const defaultValueFromArray = getElementPropertyResolvedCount(
      getItemByIdOrName(
        defaultValue,
        nameOrId,
        `No default value found for property ${nameOrId}`,
      ),
    );
    return getElementPropertyResolvedCount(
      property,
      defaultValueFromArray,
    ) as R;
  }

  return getElementPropertyResolvedCount(property, defaultValue) as R;
};

export const getElementPropertyResolvedCountRecord = (
  elementOrProps: IPropertiesOrPropertyParent | undefined,
): PropertyResolvedCountRecord =>
  getElementProperties(elementOrProps).reduce((acc, property) => {
    return {
      ...acc,
      [property.name]: getElementPropertyResolvedCount(property),
    };
  }, {});

export const getElementPropertyInputType = (
  prop: IElementProperty | IFactoryProperty,
): ElementPropertyInputType => {
  return prop?.type ?? ElementPropertyInputType.Expression;
};

/**
 * Test if a property is an element property
 * @param prop
 * @returns
 */
export const isElementProperty = (prop: unknown): prop is IElementProperty => {
  // Cast once to avoid TS errors
  const propObj = prop as IElementExpressionProperty;

  return (
    isNonArrayObject(propObj) &&
    hasDefinedProperties(propObj, 'type', 'name') &&
    isElementPropertyInputType(propObj.type)
  );
};

const isElementPropertyInputType = (
  type: unknown,
): type is ElementPropertyInputType => {
  return isOneOf(ELEMENT_PROPERTY_INPUT_TYPES, type);
};

export const isElementExpressionProperty = (
  prop: IElementProperty | undefined,
): prop is IElementExpressionProperty =>
  !!prop &&
  getElementPropertyInputType(prop) === ElementPropertyInputType.Expression;

export const isElementSelectProperty = (
  prop: unknown,
): prop is IElementSelectProperty =>
  isNonArrayObject(prop) &&
  'type' in prop &&
  prop.type === ElementPropertyInputType.Select;

export const isElementSwitchProperty = (
  prop: IElementProperty | undefined,
): prop is IElementSwitchProperty =>
  prop?.type === ElementPropertyInputType.Switch;

export const isElementSbefProperty = (
  prop: IElementProperty | undefined,
): prop is IElementSelectProperty & { name: ElementPropertyName.SBEFCode } =>
  (prop?.name as ElementPropertyName) === ElementPropertyName.SBEFCode;

export const isElementLifetimeProperty = (
  prop: IElementProperty | undefined,
): boolean =>
  (prop?.name as ElementPropertyName) === ElementPropertyName.Lifetime;

export const isElementMutualProperty = (
  prop: IElementProperty | undefined,
  filterReusedContent = false,
): boolean => {
  return mutualProperties.some(
    (mutualProperty) =>
      mutualProperty.name === prop?.name ||
      (filterReusedContent && prop?.name === reusedContentProperty.name),
  );
};

/***
 * Test if two properties are equal
 */
export const isElementPropertiesEqual = (
  propA: IElementProperty,
  propB: IElementProperty,
): boolean => {
  const typeA = getElementPropertyInputType(propA);
  const typeB = getElementPropertyInputType(propB);

  // Names must always match for any type
  if (propA.name !== propB.name || typeA !== typeB) {
    return false;
  }

  // ExpressionValues is objects and can't be compared with ===
  if (
    isElementExpressionProperty(propA) &&
    isElementExpressionProperty(propB)
  ) {
    return (
      propA.count?.expression === propB.count?.expression &&
      propA.unit === propB.unit
    );
  }
  // All other types can be compared with ===
  return propA.count === propB.count;
};

/**
 * Test if a multiple or a single property are members of a set of properties.
 * All properties must match to return true.
 * @param elementOrProps An element or an array of properties
 * @param properties One or more properties to test
 * @returns
 */
export const hasElementProperties = (
  elementOrProps: IPropertiesOrPropertyParent | undefined,
  ...properties: IElementProperty[]
): boolean => {
  return properties.every((p) => getExistingElementProperty(elementOrProps, p));
};

/**
 * Loop through a path of elements (in reverse order) to get the closest defined value for a element
 * @param path
 * @param name
 * @param defaultValue
 * @param ignoreZero
 * @returns
 */
export const getResolvedCountInPath = <D extends GetResolvedCountDefault>(
  path: ElementPath,
  name: ElementPropertyName,
  defaultValue: D,
  ignoreZero: boolean = true,
): GetResolvedCountReturnValue<D> => {
  const value = findMap(path.toReversed(), (e) => {
    const resolved = getElementPropertyResolvedCountByNameOrId(e, name);
    return ignoreZero && resolved === 0 ? undefined : resolved;
  });
  // If no value was found, return the default value by calling the function with no
  return (value ??
    getElementPropertyResolvedCountByNameOrId(
      path[0],
      name,
      defaultValue,
    )) as GetResolvedCountReturnValue<D>;
};

/**
 * Get an existing property from a list of properties or element based on provided.
 * Similar means that the name and type must match.
 * The source is ignored so the property might be from a different recipe/category.
 * @param elementOrProps An element or an array of properties
 * @param property The property to find
 * @returns
 */
const getExistingElementProperty = (
  elementOrProps: IPropertiesOrPropertyParent | undefined,
  property: IElementProperty,
): IElementProperty | undefined => {
  const elementProperties = getElementProperties(elementOrProps);

  const source = getElementPropertySource(property);
  const sourceId = getElementPropertySourceId(property);
  const type = getElementPropertyInputType(property);
  const { name, id } = property;
  return elementProperties.find((p) => {
    // Always match if id is the same
    if (id && id === p.id) {
      return true;
    }

    // If it's a select property, make sure options are equal
    if (
      isElementSelectProperty(property) &&
      isElementSelectProperty(p) &&
      !isEqual(property.options, p.options)
    ) {
      return false;
    }

    // Source and name must match
    return (
      getElementPropertySource(p) === source &&
      getElementPropertySourceId(p) === sourceId &&
      p.type === type &&
      p.name === name
    );
  });
};

/**
 * Check if an array of properties is equal to another array of properties
 */
export const isElementPropertyListEqual = (
  propsA: IElementProperty[],
  propsB: IElementProperty[],
): boolean => {
  if (propsA?.length === propsB?.length) {
    return (
      propsA.every((pA) => {
        return propsB.some((pB) => isElementPropertiesEqual(pB, pA));
      }) &&
      propsB.every((pB) => {
        return propsA.some((pA) => isElementPropertiesEqual(pB, pA));
      })
    );
  }
  return false;
};

/**
 * The properties specific to a recipe or category properties
 * used to categorize a property
 */
type ElementPropertySourceProperties = Pick<
  IElementProperty,
  ElementPropertySource
>;

/**
 * Get the type of an element property
 * @param property
 * @returns
 */
export const getElementPropertySource = (
  property: ElementPropertySourceProperties,
): ElementPropertySource | undefined => {
  if (isElementPropertySourceCategory(property)) {
    return ElementPropertySource.Category;
  } else if (isElementPropertySourceRecipe(property)) {
    return ElementPropertySource.Recipe;
  }
  // Can this happen?
  return undefined;
};

export const getElementPropertySourceId = (
  property: ElementPropertySourceProperties,
): ElementPropertySourceID | undefined => {
  // make sure '' is not returned
  return property.category_id || property.recipe_id || undefined;
};

/**
 * Filter list of properties to pass back only those properties that should be added.
 * Properties not already existing in other words.
 * @param element
 * @param source
 * @param properties List of properties past to applyElementPropertiesOfType
 * @returns If returns undefined no changes should be made. If returns an empty array all properties of that type should be removed
 */
const getPropertiesToApply = (
  element: IElement,
  source: ElementPropertySource | undefined,
  properties?: IElementProperty[],
): IElementProperty[] | undefined => {
  // undefined properties should not change anything
  if (properties === undefined) {
    return undefined;
  }
  // Empty properties should remove all properties of that type (if type is defined)
  if (properties.length === 0) {
    return !source ? undefined : properties;
  }

  const sourceId = getElementSourceId(element, source);

  // Make sure all properties have id and [type] set
  properties = properties.map((p) =>
    applyIdAndSourceToProperty(
      validatePropertySource(element, p),
      source,
      sourceId,
    ),
  );

  // Return undefined if no changes should be made
  return getElementPropertiesBySource(element, source).length ===
    properties.length && hasElementProperties(element, ...properties)
    ? undefined
    : properties;
};

/**
 * Apply properties to an element. If already existing type is category_id or recipe_id we should replace all properties of that type
 * @param element
 * @param source
 * @param props
 * @returns
 */
export const applyElementPropertiesOfSource = (
  element: IElement,
  source: ElementPropertySource | undefined,
  props?: IElementProperty[],
): IElement => {
  const newProperties = getPropertiesToApply(element, source, props);

  // undefined should not change anything
  if (newProperties === undefined) {
    return element;
  }

  let properties: IElementProperty[] = getElementProperties(element).slice();

  // If not type just push the new properties
  if (!source) {
    return addElementProperties(element, ...newProperties);
  }

  // All properties that will be removed
  const removedProperties = properties.filter(
    (p) =>
      isElementPropertyOfSource(p, source) ||
      newProperties.some(
        (np) => !isElementPropertySourceCategory(np) && np.name === p.name,
      ),
  );

  // Remove them from properties array
  properties = properties.filter((p) => !removedProperties.includes(p));

  // Add all new properties
  properties.push(
    ...newProperties.map((p) => {
      // Reuse count from removed properties if possible
      const existing = getExistingProperty(removedProperties, p);

      return createElementProperty(
        {
          ...p,
          count: existing?.count ?? p.count,
          id: existing?.id ?? p.id,
        } as IElementProperty,
        undefined,
        false,
      );
    }),
  );

  return {
    ...element,
    properties: validateElementProperties(properties),
  };
};

const getExistingProperty = (
  properties: IElementProperty[],
  newProperty: IElementProperty,
): IElementProperty | undefined => {
  // Find a property with the same name and type
  const reusable = properties.find(
    (p) => p.name === newProperty.name && p.type === newProperty.type,
  );

  // If it's a select property, make sure the reusable count is an option in the new property
  if (
    isElementSelectProperty(reusable) &&
    !getOptionByValue(newProperty, getPropertyCount(reusable, false))
  ) {
    return undefined;
  }

  return reusable;
};

const getOptionByValue = (
  property: IElementProperty,
  value?: IElementPropertyOption['value'],
): IElementPropertyOption | undefined => {
  return isElementSelectProperty(property)
    ? getSelectPropertyOptions(property).find((o) => o.value === value)
    : undefined;
};

/**
 * If you can change the name or delete the property.
 * If the property belongs to the original element category or a nodon recipe, only the value can be modified.
 * @param prop
 * @returns true if the property can be deleted or renamed, false if only the value can be modified.
 */
export const isFullyEditableProperty = (prop: IElementProperty): boolean =>
  !isNodonRecipeProperty(prop) &&
  !isElementPropertySourceCategory(prop) &&
  !isElementQuantityName(prop.name) &&
  !prop.hidden &&
  !prop.readonly;

/**
 * Check if the property is a category property
 * @param prop
 * @returns
 */
export const isElementPropertySourceCategory = (
  prop: ElementPropertySourceProperties,
): boolean => isElementPropertyOfSource(prop, ElementPropertySource.Category);

/**
 * Check if the property is a recipe property
 * @param prop
 * @returns
 */
export const isElementPropertySourceRecipe = (
  prop: ElementPropertySourceProperties,
): boolean => isElementPropertyOfSource(prop, ElementPropertySource.Recipe);

/**
 * Check if the property is neither a category or recipe property
 * @param prop
 * @returns
 */
export const isElementPropertySourceNone = (
  prop: ElementPropertySourceProperties,
): boolean =>
  prop &&
  !isElementPropertySourceRecipe(prop) &&
  !isElementPropertySourceCategory(prop);

/**
 * Check if element is of a certain source (category, recipe or neither)
 * @param prop
 * @param source
 * @returns
 */
export const isElementPropertyOfSource = (
  prop: ElementPropertySourceProperties,
  source: ElementPropertySource | undefined,
): boolean => {
  if (!source) {
    return isElementPropertySourceNone(prop);
  }
  return prop && !!prop[source];
};

export const isIconElementProperty = (prop: IElementProperty): boolean => {
  return iconQuantityNames.includes(prop.name as IconQuantityName);
};

/**
 * Get all properties by a certain source
 * @param elementOrProps
 * @param source
 * @returns
 */
export const getElementPropertiesBySource = (
  elementOrProps: IPropertiesOrPropertyParent,
  source: ElementPropertySource | undefined,
): IElementProperty[] =>
  getElementProperties(elementOrProps).filter((p) =>
    isElementPropertyOfSource(p, source),
  );

/**
 * Get all properties from category source
 * @param elementOrProps
 * @returns
 */
export const getElementPropertiesByCategorySource = (
  elementOrProps: IPropertiesOrPropertyParent,
): IElementProperty[] =>
  getElementPropertiesBySource(elementOrProps, ElementPropertySource.Category);

/**
 * Get all properties from recipe source
 * @param elementOrProps
 * @returns
 */
export const getElementPropertiesByRecipeSource = (
  elementOrProps: IPropertiesOrPropertyParent,
): IElementProperty[] =>
  getElementPropertiesBySource(elementOrProps, ElementPropertySource.Recipe);

/**
 * Get all properties that are not of a certain source.
 * @param elementOrProps
 * @param source
 * @returns
 */
export const getElementPropertiesNotOfSource = (
  elementOrProps: IPropertiesOrPropertyParent,
  source: ElementPropertySource | undefined,
): IElementProperty[] =>
  getElementProperties(elementOrProps).filter(
    (p) => !isElementPropertyOfSource(p, source),
  );

/**
 * Get all element properties and default to empty array if it doesn't exist.
 * @param elementOrProps An element, category, recipe or an array of properties
 * @returns
 */
export const getElementProperties = <
  T extends IPropertiesOrPropertyParent | ElementWithProperties,
  R = T extends ElementQuantityRecord
    ? IElementQuantityProperty
    : IElementProperty,
>(
  elementOrProps:
    | IPropertiesOrPropertyParent
    | ElementWithProperties
    | undefined,
): R[] => {
  if (!elementOrProps) {
    return EMPTY_PROPERTIES as R[];
  }
  if (Array.isArray(elementOrProps)) {
    return elementOrProps as R[];
  }
  if ('properties' in elementOrProps) {
    return (elementOrProps.properties ?? EMPTY_PROPERTIES) as R[];
  }
  if (isElementQuantityRecord(elementOrProps)) {
    return Object.values(elementOrProps) as R[];
  }
  return EMPTY_ARRAY as R[];
};

export const getElementAndQuantityProperties = (
  element?: OneOfElements,
): (IElementProperty | IElementQuantityExpressionProperty)[] => {
  const properties = getElementProperties(element);
  const quantityProperties = getElementQuantityProperties(element);
  return [...properties, ...quantityProperties];
};

/**
 * Replace element properties in Element or array of properties
 * @param originalElementOrProperties
 * @param newProperties
 * @returns
 */
const replaceElementProperties = <T extends IPropertiesOrPropertyParent>(
  originalElementOrProperties: T,
  ...newProperties: IElementProperty[]
): T => {
  const properties = getElementProperties(originalElementOrProperties);
  let updatedProperties = properties;

  newProperties.forEach((p) => {
    updatedProperties = cloneReplaceById(updatedProperties, p.id, p);
  });

  // Nothing changed, don't modify anything
  if (shallowEqual(properties, updatedProperties)) {
    return originalElementOrProperties;
  }
  if (Array.isArray(originalElementOrProperties)) {
    return updatedProperties as T;
  }
  return { ...originalElementOrProperties, properties: updatedProperties };
};

/**
 * Get the id of either the recipe or the category.
 * Note that auto recipes are resolved to their original recipe id.
 * @param element
 * @param source
 * @returns
 */
export const getElementSourceId = (
  element: OneOfElements,
  source: ElementPropertySource | undefined,
): ElementPropertySourceID | undefined => {
  if (source === ElementPropertySource.Category) {
    return getElementCategoryId(element);
  }
  if (source === ElementPropertySource.Recipe) {
    return resolveAutoRecipeId(getRecipeId(element));
  }
  return undefined;
};

/**
 * If property has min or max, limit the resolved value.
 * @param property
 * @returns
 */
export const limitElementPropertyResolvedCount = <T extends IElementProperty>(
  property: T,
): T => {
  if (isElementExpressionProperty(property)) {
    const { min, max, count } = property;

    // Currently only limit count, not fallbackCount
    if (count) {
      const limited = clamp(count.resolved, min, max);

      return applyChanges(property, { count: { resolved: limited } });
    }
  }
  return property;
};

export const getUniquePropertyName = (
  element: OneOfElements,
  property: IElementProperty,
  newName: string,
): string | undefined => {
  const otherPropertyNames = getElementProperties(element)
    .filter((p) => p.id !== property.id)
    .map((p) => p.name);

  const propertyNames = [
    ...elementQuantityNames, // Make sure to not use the same name as a quantity
    ...otherPropertyNames,
  ];

  const convertedName = convertName(newName);

  return findFreeName(propertyNames, convertedName, {
    delimiter: '',
    startIndex: 1,
  });
};

/**
 * Get display name of a property. Mainly to style the name of the category/quantity properties
 * @param property
 * @returns
 */
export const getPropertyDisplayName = (property: IElementProperty): string => {
  const name = property.name;

  if (isElementSbefProperty(property)) {
    return 'SBEF Code';
  }

  // Invert the area_ property names
  if (isElementQuantityName(name)) {
    return getElementQuantityFormattedName(name);
  }

  return isFullyEditableProperty(property) ? name : labelFromValue(name);
};

const PROPERTY_NAME_MAX_LENGTH = 15;
const PROPERTY_COUNT_MAX_LENGTH = 25;
const PROPERTY_NAME_AND_COUNT_MAX_LENGTH = 35;

const nameAndCountTooLong = (property: IElementProperty): boolean => {
  return (
    typeof property.count === 'string' &&
    property.name.length + property.count.length >
      PROPERTY_NAME_AND_COUNT_MAX_LENGTH
  );
};

export const getNameTooltip = (property: IElementProperty): string => {
  if (property.description) {
    return property.description;
  }
  const nameTooLong = property.name.length > PROPERTY_NAME_MAX_LENGTH;

  return nameTooLong && nameAndCountTooLong(property)
    ? getPropertyDisplayName(property)
    : '';
};

export const getCountTooltip = (property: IElementProperty): string => {
  if (typeof property.count !== 'string') {
    return '';
  }
  const countTooLong = property.count.length > PROPERTY_COUNT_MAX_LENGTH;

  return countTooLong && nameAndCountTooLong(property)
    ? labelFromValue(property.count)
    : '';
};
