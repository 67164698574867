import {
  Divider,
  ListItemButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { CSSProperties, useCallback } from 'react';
import { EllipsisText } from '../../EllipsisText';
import { MENU_ITEM_HEIGHT } from '../../../../../shared/constants';
import { getSBEFDividerItems } from '../../../../../shared/templates/categories/categories-properties.helpers';
import { ConcreteType } from '../../../../../shared/templates/categories/concrete/concrete.model';
import { BoardType } from '../../../../../shared/templates/categories/gypsum/gypsum.model';
import { InsulationMaterial } from '../../../../../shared/templates/categories/insulation/insulation.model';
import { WoodType } from '../../../../../shared/templates/categories/wood/wood.model';
import { otherNodonProduct } from '../../../../../shared/templates/categories/other-product';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';

interface SelectMenuItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  value: string | number;
  label?: string;
  selected?: boolean;
  showTooltip?: boolean;
  indentAfterFirstWord?: boolean;
  disableDivider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSelect?: (value: string | number) => void;
}

const SelectMenuItem: React.FC<SelectMenuItemProps> = ({
  value,
  label: labelInput,
  style,
  selected,
  showTooltip,
  indentAfterFirstWord,
  disableDivider,
  onClick,
  onSelect,
}) => {
  const label =
    labelInput ?? (typeof value === 'number' ? formatThousands(value) : value);
  const [first, ...rest] = indentAfterFirstWord ? label.split(' ') : [];

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) {
        onClick(event);
      }
      if (onSelect) {
        onSelect(value);
      }
    },
    [onClick, onSelect, value],
  );

  return (
    <>
      {!disableDivider && <DividerItem code={label} style={style} />}
      <ListItemButton
        style={{ ...style, zIndex: 1 }}
        data-item={value}
        onClick={handleClick}
        selected={selected}
      >
        <Typography {...listItemTextStyle}>
          <EllipsisText showTooltip={showTooltip}>
            {first || label}
          </EllipsisText>
        </Typography>

        {rest.length > 0 && (
          <Typography {...listItemTextStyle}>
            <EllipsisText showTooltip={showTooltip}>
              {rest.join(' ')}
            </EllipsisText>
          </Typography>
        )}
      </ListItemButton>
    </>
  );
};

interface DividerItemProps {
  /** These styles are needed if virtualization is enabled */
  style?: CSSProperties;
  // item: IElementPropertyOption;
  code: string;
}

const DividerItem = ({ style, code }: DividerItemProps) => {
  const isNodonProduct = otherNodonProduct && code === otherNodonProduct.name;

  const isDividerItem = dividerItems.includes(code);

  if (!isNodonProduct && !isDividerItem) {
    return null;
  }
  if (!style) {
    return <Divider />;
  }
  return (
    <Divider
      style={{
        ...style,
        zIndex: 0,
        top: isNodonProduct ? style.top : Number(style.top) - MENU_ITEM_HEIGHT,
      }}
    />
  );
};

const dividerItems: string[] = [
  ConcreteType.Other,
  ConcreteType.BalconyAndStairs,
  ConcreteType.Columns,
  BoardType.CelluloseFibre,
  InsulationMaterial.EPS,
  WoodType.OSB,
  ...getSBEFDividerItems(),
];

const listItemTextStyle: TypographyProps = {
  minWidth: 30,
  variant: 'body2',
  display: 'flex',
  alignItems: 'center',
} as const;

export default SelectMenuItem;
