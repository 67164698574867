import React, { memo, useCallback, useEffect, useState } from 'react';
import { IProjectInfo } from '../../../../../../shared/models/project.interface';
import { useIsSelected, useUIState } from '../../../../store/ui';
import { useToggleElementExpanded } from '../../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../../shared/helpers/element_helpers';
import {
  CONTENT_CELL_WIDTH,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../../../ElementList/list.constants';
import { useBooleanState } from '../../../../hooks/hooks';
import { useTriggerContextMenu } from '../../../../hooks/menu.hooks';
import { useListRowStyles } from '../../../ElementList/list.style';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import RestrictProjectButton from '../../../RestrictProjectButton';
import { Box, Tooltip } from '@mui/material';
import ProjectOwnerMenu from '../../../ProjectOwnerMenu';
import ExpressionTypography from '../../../ExpressionTypography';
import ExpandButton from '../../Buttons/ExpandButton';
import DateText from '../../../DateText';
import { Row } from '../../Row';
import { RowCell } from '../../RowCell';
import { IProjectFolder } from '../../../../../../shared/models/folder.interface';
import { isProjectFolder } from '../../../../../../shared/helpers/project-folder.helpers';
import ProjectListItemNameInput from './ProjectListItemNameInput';
import ProjectListItemBarCharts from './ProjectListItemBarCharts';
import ProjectListItemKebabMenu from './ProjectListItemKebabMenu';

interface ProjectListItemProps {
  item: IProjectInfo | IProjectFolder;
  indentation?: number;
  autoFocus?: boolean;
}

const ProjectListItem: React.FC<ProjectListItemProps> = ({
  item,
  indentation = 0,
  autoFocus,
}) => {
  const { setAddedElementId, setSelectedProjectFolderId } = useUIState(
    'setAddedElementId',
    'setSelectedProjectFolderId',
  );
  const { classes: listClasses } = useListRowStyles();
  const { id, gfa = 0 } = item;

  const toggleElementExpanded = useToggleElementExpanded(item);
  const triggerContextMenu = useTriggerContextMenu(id);

  const selected = useIsSelected(item);
  const deactivated = isDeactivated(item);
  const isFolder = isProjectFolder(item);

  const [hover, startHover, endHover] = useBooleanState(false);

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const [kebabMenuAnchor, setKebabMenuAnchor] = useState<Element>();

  const handleFolderClick = useMouseEventCallback(
    () => {
      if (isFolder) {
        setSelectedProjectFolderId(selected ? undefined : item.id);
      }
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  const editName = useCallback(() => {
    startEditingName();
    endHover();
    setKebabMenuAnchor(undefined);
  }, [endHover, startEditingName]);

  const closeKebabMenu = useCallback(() => {
    setKebabMenuAnchor(undefined);
    endHover();
  }, [endHover]);

  useEffect(() => {
    if (autoFocus) {
      if (isFolder) {
        setSelectedProjectFolderId(item.id);
        setAddedElementId(undefined);
        return;
      }
      startEditingName();
    }
  }, [
    autoFocus,
    isFolder,
    item,
    setAddedElementId,
    setSelectedProjectFolderId,
    startEditingName,
  ]);

  return (
    <Row
      padding={2}
      spacing={8}
      classes={listClasses}
      deactivated={deactivated}
      height={LIST_ITEM_HEIGHT}
      hover={hover}
      selected={selected}
      onContextMenu={triggerContextMenu}
      onDoubleClick={toggleElementExpanded}
      onMouseLeave={endHover}
      onMouseEnter={startHover}
      onClick={handleFolderClick}
    >
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT_PROJECT_LIST}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT_PROJECT_LIST}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
          <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
            <RestrictProjectButton hover={hover} project={item} />
          </RowCell>

          <RowCell width={ROOT_CELL_WIDTH.ICON}>
            <ExpandButton element={item} disabled={!isFolder} />
          </RowCell>

          <RowCell
            responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT_PROJECT_LIST}
          >
            <ProjectListItemNameInput
              item={item}
              autoFocus={autoFocus}
              isEditingName={isEditingName}
              onCancel={stopEditingName}
              onClick={handleFolderClick}
            />
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.DATE}>
            <DateText
              date={item.updated_at}
              relativeToCurrentTime
              tootltipPrefix="Updated at"
              pl={2}
            />
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.EXPRESSION} align="right">
            <Tooltip title="Gross floor area">
              <Box pr={2}>
                <ExpressionTypography value={gfa} unit={'m²'} />
              </Box>
            </Tooltip>
          </RowCell>
        </Row>
      </RowCell>

      <RowCell width={ROOT_CELL_WIDTH.BAR} responsiveWidth={1}>
        {!isFolder && (
          <ProjectListItemBarCharts gfa={gfa} results={item.results} />
        )}
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.EXPRESSION} align="right">
        <Tooltip title="kgCO2e/m² GFA">
          <Box pr={2}>
            <ExpressionTypography value={item.target ?? undefined} />
          </Box>
        </Tooltip>
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.DATE}>
        <DateText
          date={item.completed_at}
          tootltipPrefix="Completion date"
          format="yyyy-MM-dd"
          pl={2}
        />
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.OWNER + CONTENT_CELL_WIDTH.ICON}>
        <RowCell pl={8}>
          {!isFolder && (
            <ProjectOwnerMenu projectInfo={item} onClose={endHover} />
          )}
        </RowCell>
        <RowCell width={CONTENT_CELL_WIDTH.ICON} align="right">
          <ProjectListItemKebabMenu
            item={item}
            anchor={kebabMenuAnchor}
            hover={hover}
            onOpen={setKebabMenuAnchor}
            onClose={closeKebabMenu}
            onEdit={editName}
          />
        </RowCell>
      </RowCell>
    </Row>
  );
};

export default memo(ProjectListItem);
