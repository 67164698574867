import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useState, MouseEvent } from 'react';
import RecipeActionMenu from './RecipeActionMenu';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useRecipe } from '../../../../hooks/useRecipes';
import { RecipeLabel } from './RecipeLabel';
import { useIsReadonly } from '../../../../hooks/user.hook';
import RecipeSelector from './RecipeSelector/RecipeSelector';

interface RecipePanelProps {
  element: IElement;
}

const RecipePanel: React.FC<RecipePanelProps> = ({ element }) => {
  const readonly = useIsReadonly();
  const recipe = useRecipe(element);

  const [anchor, setAnchor] = useState<HTMLElement>();

  const openRecipeSelector = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );

  const closeRecipeSelector = useCallback(() => setAnchor(undefined), []);

  return (
    <Box pt={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">Recipe</Typography>
        <RecipeActionMenu element={element} recipe={recipe} />
      </Box>

      <Button
        fullWidth
        size="medium"
        variant="contained"
        color="secondary"
        disabled={readonly}
        onClick={openRecipeSelector}
        sx={{ textTransform: 'capitalize' }}
      >
        <RecipeLabel element={element} recipe={recipe}></RecipeLabel>
      </Button>

      {recipe?.description && (
        <Typography variant="body2" component="div" p={2}>
          {recipe?.description}
        </Typography>
      )}

      {!!anchor && (
        <RecipeSelector onClose={closeRecipeSelector} element={element} />
      )}
    </Box>
  );
};

export default RecipePanel;
