import { useCallback, useMemo } from 'react';
import {
  getSelectedOrganizationQuota,
  isReadonlyOrganization,
} from '../../../../shared/helpers/organization_helpers';
import { useProjectsTotalGFA } from '../project';
import { StoreErrorMessage } from '../store_errors';
import {
  useOrganizationStore,
  IOrganizationsState,
} from './organization.store';
import { useUser } from '../../hooks/user.hook';

const selectedOrganizationSelector = (
  state: IOrganizationsState,
): IOrganizationsState['selectedOrganization'] => state.selectedOrganization;

const organizationsSelector = (
  state: IOrganizationsState,
): IOrganizationsState['organizations'] => state.organizations;

const setOrganizationsSelector = (
  state: IOrganizationsState,
): IOrganizationsState['setOrganizations'] => state.setOrganizations;

const setSelectedOrganization = (
  state: IOrganizationsState,
): IOrganizationsState['setSelectedOrganization'] =>
  state.setSelectedOrganization;

/**
 * Get selected organization without causing rerenders
 * @param required Pass true to crash if no organization is selected
 * @returns
 */
export const getSelectedOrganization = <
  T extends ReturnType<typeof selectedOrganizationSelector>,
  P extends true | false | undefined = false,
  R = P extends true ? NonNullable<T> : T | undefined,
>(
  required?: P,
): R => {
  const organization = selectedOrganizationSelector(
    useOrganizationStore.getState(),
  );
  if (required && organization === undefined) {
    throw new Error(StoreErrorMessage.OrganizationMissing);
  }
  return organization as R;
};

/**
 * Get available organizations without causing rerenders
 * @returns
 */
export const getOrganizations = (): ReturnType<typeof organizationsSelector> =>
  organizationsSelector(useOrganizationStore.getState());

/**
 * Get selected organization and trigger a rerender when it changes
 * @returns
 */
export const useSelectedOrganization = (): ReturnType<
  typeof selectedOrganizationSelector
> => useOrganizationStore(selectedOrganizationSelector);

/**
 * Set selected organization
 * @returns
 */
export const useSetSelectedOrganization = (): ReturnType<
  typeof setSelectedOrganization
> => useOrganizationStore(setSelectedOrganization);

/**
 * Get available organizations and trigger a rerender when it changes
 * @returns
 */
export const useOrganizations = (): ReturnType<typeof organizationsSelector> =>
  useOrganizationStore(organizationsSelector);

/**
 * Set available organizations
 * @returns
 */
export const useSetOrganizations = (): ReturnType<
  typeof setOrganizationsSelector
> => useOrganizationStore(setOrganizationsSelector);

/**
 * Check if total Groos Floor Area for all the projects belonging to the
 * selected organization exceeds the organization's quota
 */
export const useOrganizationGFAQuotaIsExceeded = (): boolean => {
  const user = useUser();

  const selectedOrganization = getSelectedOrganization();
  const totalGFA = useProjectsTotalGFA();

  const quota = useMemo(
    () =>
      getSelectedOrganizationQuota(user?.organizations, selectedOrganization),
    [selectedOrganization, user?.organizations],
  );
  return quota === undefined ? false : quota === 0 || totalGFA > quota;
};

/**
 * Check if organizations includes the template organization.
 * If it does, it means that the source organizations (eg [source].organizations) is readonly,
 * unless the selected organization is the template organization.
 */
export const useIsReadonlyOrganization = () => {
  const organization = useSelectedOrganization();
  return useCallback(
    (organizations: string[]) =>
      isReadonlyOrganization(organization, organizations),
    [organization],
  );
};
