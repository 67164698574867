import sbefJson from './sbef_codes.json';
import { toLookup } from '../helpers/utils.helpers';

export type SBEFCodeLanguage = 'sv' | 'en';

export interface SBEFCode {
  id: string;
  description: Record<SBEFCodeLanguage, string>;
}

export const sbefCodes = sbefJson as SBEFCode[];
export const sbefCodesLookup = toLookup(sbefCodes);
