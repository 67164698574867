import {
  IBuildingVersion,
  IElementID,
  IProductElement,
  OneOfElements,
  Project,
} from '../models/project.interface';
import {
  BOVERKET_ID_PREFIX,
  CUSTOM_ID_PREFIX,
  NODON_ID_PREFIX,
  OKOBAUDAT_ID_PREFIX,
  IProduct,
  ProductCategories,
  ProductID,
  ProductJSON,
  ProductRecord,
} from '../models/product.interface';
import {
  getAllProductElements,
  getAllBuildingVersions,
  getBuilding,
  getChildElements,
  isProductElement,
} from '../helpers/recursive_element_helpers';
import {
  ArrayOrRecord,
  ItemOrItemId,
  PartialRecord,
  SemiPartial,
} from '../models/type_helpers.interface';
import {
  updateBuildingVersionProperties,
  updateElements,
} from '../helpers/project_helpers';
import { cloneDeep, isObject, mapValues, uniq } from 'lodash';
import {
  getId,
  hasDefinedProperties,
  omit,
  omitUndefined,
} from '../helpers/object_helpers';
import { ConversionFactors } from '../models/unit.interface';
import { DateTime } from 'luxon';
import { Recipe } from '../models/recipe.interface';
import {
  getProductIdsInRecipes,
  getRecipesUsedInElement,
} from './recipe_helpers';
import shallowEqual, { isDefined, keyArrayToRecord } from './array_helpers';
import { required } from './function_helpers';
import { getTimestamp } from './date.helpers';
import { mergeConversionFactors } from './conversion-factors.helpers';

// Don't use createProject here since it will cause circular dependencies
const emptyProduct: Readonly<IProduct> = Object.freeze({
  id: '',
  name: 'No product found',
  unit: 'kg',
  conversion_factors: { kg: 0, 'm³': 0 },
  external_identifiers: {},
  category_property_value_record: {},
  source: 'Boverket',
  categories: {},
  created_at: getTimestamp(),
  updated_at: getTimestamp(),
  characteristics: {},
});

/**
 * DEPRECATED: Find a product based on an ID. Use getProductById instead.
 *
 * @param productsLookup
 * @param version
 * @param id
 * @param allowUndefined If false it will return an empty product if the product is not found
 * @returns
 */
export const getProductById = (
  productsLookup: ProductRecord | undefined,
  version: IBuildingVersion | undefined,
  id: ProductID,
  reportError = false,
): IProduct => {
  // Always use the product from the version if it exists
  const product = version?.products[id] || productsLookup?.[id];

  // TODO: Sometimes a product doesn't exist, why?
  if (!product) {
    if (reportError) {
      console.error('Product not found', id);
    }
    return cloneDeep(emptyProduct);
  }

  return product;
};

/**
 * Return a IProductElement.product_id or Product.id or the provided id if it's a string
 * @param elementOrId
 * @returns
 */
export const getProductId = (
  elementOrId: IProductElement | IProduct | IProductElement['product_id'],
): ProductID => {
  if (typeof elementOrId === 'string') {
    return elementOrId;
  }
  if (isProductElement(elementOrId)) {
    return elementOrId.product_id;
  }
  return required(elementOrId?.id, 'missing product id');
};

/**
 * Use this in cases where product must exist
 * @param products
 * @param id
 * @param require if true, it will throw an error if product is not found
 */
export const getProduct = <T extends boolean = true>(
  products: ArrayOrRecord<IProduct> | undefined,
  elementOrId: IProductElement | IProductElement['product_id'],
  require: T = true as T,
) => {
  const id = getProductId(elementOrId);
  const product = Array.isArray(products)
    ? products?.find((p) => p.id === id)
    : (products as PartialRecord<string, IProduct>)?.[id];

  return required(product, require);
};

/**
 * Get a record of all used products from an element
 * @param element Root element from which we wanna get all used products from
 * @param productsLookup Record of all available products
 * @param recipes Include the list of recipes in store to make sure all products referred by recipes are included
 * @returns
 */
export const getProductRecordFromElement = (
  element: OneOfElements,
  productsLookup: ProductRecord,
  recipes?: Recipe[],
): Record<ProductID, IProduct> => {
  return getProductIdsInElement(element, recipes).reduce<
    Record<ProductID, IProduct>
  >(
    (acc, id) => {
      const product = productsLookup[id];
      return product
        ? {
            ...acc,
            [id]: product,
          }
        : acc;
    },
    {} as Record<ProductID, IProduct>,
  );
};

/**
 * Merge all version.product records into one record
 * @param project
 */
export const getProjectProductsRecord = (project: Project): ProductRecord =>
  getAllBuildingVersions(project).reduce(
    (acc, version) => ({
      ...acc,
      ...version.products,
    }),
    {},
  );

/**
 * Get all unique product ids that exists within an element (including children)
 * @param element
 * @param recipes Passing recipes will make sure all ProductIDs in referred recipes are included
 * @returns
 */
export const getProductIdsInElement = (
  element: OneOfElements,
  recipes?: Recipe[],
): ProductID[] => {
  const usedRecipes = getRecipesUsedInElement(element, recipes);
  const recipeProductIds = getProductIdsInRecipes(...usedRecipes);

  // Include generic product (when mapping an EPD to the product) if present
  const productIds = Object.values(getProductIdsInElementMap(element)).flat();

  return uniq([...productIds, ...recipeProductIds]);
};

export const getProductIdsInElementMap = (
  element: OneOfElements,
): Record<IElementID, ProductID[]> => {
  // Include generic product (when mapping an EPD to the product) if present
  return getAllProductElements(element).reduce(
    (acc, productElement) => ({
      ...acc,
      [productElement.id]: getProductIdsInProductElement(productElement),
    }),
    {},
  );
};

/**
 * Get all product ids in a product element (generic product id as second id if it exists)
 * @param param0
 * @returns
 */
export const getProductIdsInProductElement = ({
  generic_product_id,
  product_id,
}: IProductElement): [ProductID] | [ProductID, ProductID] =>
  generic_product_id ? [product_id, generic_product_id] : [product_id];

/**
 * Get all product elements in an element (including children) in order of element hierarchy
 * @param element
 * @param products
 * @returns
 */
export const getProductsInElement = (
  element: OneOfElements,
  productLookup: ProductRecord,
): IProduct[] => {
  const productIds = getProductIdsInElement(element);
  return productIds.map((id) => productLookup[id]).filter(isDefined);
};

/**
 * Get all product ids from a product record or array of products
 * @param productRecordOrArray
 * @returns
 */
export const toProductIds = (
  productRecordOrArray?: ProductRecord | IProduct[] | ProductID[],
): ProductID[] =>
  Object.values(productRecordOrArray ?? []).map((a: string | IProduct) =>
    getId<IProduct>(a),
  );

/**
 * Get any product ids that is missing from the version products record.
 * @param version
 * @param recipes Passing recipes will make sure all ProductIDs in referred recipes are included
 * @returns
 */
export const getMissingProductIdsInVersion = (
  version: IBuildingVersion,
  recipes: Recipe[] = [],
): ProductID[] => {
  return getProductIdsInElement(version, recipes).filter(
    (id) => !version.products[id],
  );
};

/**
 * Get any product ids that is obsolete in the version products record.
 * @param version
 * @param recipes Passing recipes will make sure all ProductIDs in referred recipes are included
 * @returns
 */
export const getObsoleteProductIdsInVersion = (
  version: IBuildingVersion,
  recipes: Recipe[] = [],
): ProductID[] => {
  const productIds = getProductIdsInElement(version, recipes);
  return Object.keys(version.products).filter((id) => !productIds.includes(id));
};

/**
 * Get all products in productRecord that are not in missingRecord
 * @param productRecord Record of products to check
 * @param missingRecord Record to make sure they exist in
 */
export const getMissingProducts = (
  productRecord: ProductRecord,
  missingRecord: ProductRecord,
): IProduct[] =>
  Object.values(productRecord).filter((product) => !missingRecord[product.id]);

/**
 * Update a version with the latest products.
 * Will remove any obsolete products and add any missing products.
 * @param version
 * @param productsLookup
 * @param recipes Include the list of recipes in store to make sure all products referred by recipes are included
 * @returns
 */
export const updateVersionProductRecord = (
  version: IBuildingVersion,
  productsLookup: ProductRecord,
  recipes?: Recipe[],
): IBuildingVersion => {
  version = addMissingProductsToVersion(version, productsLookup, recipes);
  version = removeObsoleteProductsInVersion(version, productsLookup, recipes);

  // Return the version with updated products or original if no changes
  return version;
};

/**
 * Add all missing products to version.products.
 * Will keep any obsolete products.
 * @param version
 * @param productsLookup
 * @param recipes Include the list of recipes in store to make sure all products referred by recipes are included
 * @returns
 */
export const addMissingProductsToVersion = (
  version: IBuildingVersion,
  productsLookup: ProductRecord,
  recipes?: Recipe[],
): IBuildingVersion => {
  const missingIds = getMissingProductIdsInVersion(version, recipes);

  if (missingIds.length === 0) {
    return version;
  }

  // Map to generic ids from the productsLookup (not in version.products)
  const idToGenericMap = keyArrayToRecord(
    missingIds,
    (id) => productsLookup[id]?.generic_id,
  );
  const productIdMap = getProductIdsInElementMap(version);

  const productIdMapValues = Object.values(productIdMap); // Array<[ElementID, [ProductID, GenericProductID]]>
  // const missingProducts = cloneDeep(pick(productsLookup, ...missingIds))

  const missingProducts = missingIds.reduce((acc, id) => {
    const product = required(cloneDeep(productsLookup[id]));

    // Second item in array is the generic product id if it exists (prio generic id from the EPD from store)
    const generic_id =
      idToGenericMap[id] ?? // Prefer using conversion factors from the EPDs generic product to get the same values in every usage
      productIdMapValues.find(([prodId]) => id === prodId)?.[1];

    // Use the conversion factors from the generic product if they are missing in the EPD
    if (generic_id) {
      const generic = required(
        version.products[generic_id] ?? productsLookup[generic_id],
      );

      product.conversion_factors = mergeConversionFactors(
        generic.conversion_factors,
        product.conversion_factors,
      );
    }

    return {
      ...acc,
      [id]: product,
    };
  }, {} as ProductRecord);

  if (missingIds.length > 0) {
    version = {
      ...version,
      products: {
        // Pick missing products and clone them to avoid mutating the original
        ...missingProducts,
        ...version.products,
      },
    };
  }

  // Return the version with updated products or original if no changes
  return version;
};

/**
 * Remove any obsolete products from version.products.
 * @param version
 * @param productsLookup
 * @param recipes Include the list of recipes in store to make sure all products referred by recipes are included
 * @returns
 */
export const removeObsoleteProductsInVersion = (
  version: IBuildingVersion,
  productsLookup: ProductRecord,
  recipes?: Recipe[],
): IBuildingVersion => {
  const obsoleteIds = getObsoleteProductIdsInVersion(version, recipes);

  if (obsoleteIds.length > 0) {
    version = {
      ...version,
      products: omit(version.products, ...obsoleteIds),
    };
  }

  // Return the version with updated products or original if no changes
  return version;
};

/**
 * Update all version.products with the latest products.
 * Will remove any obsolete products and add any missing products.
 * @param project
 * @param productRecord
 * @param recipe
 * @returns
 */
export const updateProductsInProject = (
  project: Project,
  productRecord: ProductRecord,
  recipes: Recipe[],
): Project => {
  const building = getBuilding(project);

  // No products to update, return original project
  if (!Object.keys(productRecord).length) {
    return project;
  }

  // Update the product rectord in the building
  const versions = building.versions.map((version) =>
    updateVersionProductRecord(version, productRecord, recipes),
  );

  // Nothing have changed, return original project
  if (building.versions.every((current, i) => current === versions[i])) {
    return project;
  }

  return {
    ...project,
    buildings: [{ ...building, versions }],
  };
};

/**
 * Updates all products in a project with the latest in productsLookup.
 * Will remove any unused products. Will also keep products that are removed from DB but still used in project.
 * DO NOT USE THIS TO PRODUCTS without a specific action from the user since it will overwrite any products.
 * Default behaviour should use updateVersionProductRecord instead.
 * @param project
 * @param productsLookup
 * @returns
 */
export const forceUpdateProductsInProject = (
  project: Project,
  productsLookup: ProductRecord,
  resetCost?: boolean,
): Project => {
  const versions = getAllBuildingVersions(project);

  const changes: Array<SemiPartial<IBuildingVersion, 'id'>> = versions.reduce(
    (acc, version) => {
      const { id } = version;

      // All currently used products
      const currentProducts = getProductRecordFromElement(
        version,
        version.products,
      );

      // Latest products from DB (with cost from the versions if available)
      const latestProducts = mapValues(
        getProductRecordFromElement(version, productsLookup),
        (p) => {
          const currentFactors: ConversionFactors =
            currentProducts[p.id]?.conversion_factors ?? {};
          // Keep cost from version products if set since they are editable
          const cost = resetCost
            ? p.conversion_factors['sek_A1-A3']
            : currentFactors['sek_A1-A3'] || p.conversion_factors['sek_A1-A3'];
          const conversion_factors = omitUndefined({
            ...p.conversion_factors,
            'sek_A1-A3': cost,
          });
          return { ...p, conversion_factors };
        },
      );

      const products = {
        // Keep old products removed from the db (or if productsLookup have not loaded)
        ...currentProducts,
        // Overwrite with any new products founds
        ...latestProducts,
      };

      return [...acc, { id, products }];
    },
    [] as Array<SemiPartial<IBuildingVersion, 'id'>>,
  );

  // Only modify the project if there are changes (to avoid unnecessary re-renders)
  const updatedProductsProject = updateBuildingVersionProperties(
    project,
    ...changes,
  );

  return updatedProductsProject;
};

export const isProduct = (product: unknown): product is IProduct => {
  return (
    isObject(product) &&
    hasDefinedProperties(
      product as IProduct,
      'id',
      'conversion_factors',
      'unit',
    )
  );
};

export const isCustomProduct = (productOrId: ItemOrItemId<IProduct>): boolean =>
  getId(productOrId).startsWith(CUSTOM_ID_PREFIX);

export const isBoverketProduct = (
  productOrId?: ItemOrItemId<IProduct>,
): boolean =>
  !!productOrId && getId(productOrId).startsWith(BOVERKET_ID_PREFIX);

export const isNodonProduct = (productOrId: ItemOrItemId<IProduct>): boolean =>
  getId(productOrId).startsWith(NODON_ID_PREFIX);

export const isOekobaudatProduct = (
  productOrId: ItemOrItemId<IProduct>,
): boolean => getId(productOrId).startsWith(OKOBAUDAT_ID_PREFIX);

/**
 * Get the original product ID. The ID before it was prefixed boverket_sv-SE_ or suffixed with climate improved suffixes
 * @param product
 * @returns
 */
export const getOriginalProductId = (
  productOrId: ItemOrItemId<IProduct>,
): string => {
  return getId(productOrId)
    .replace(BOVERKET_ID_PREFIX, '')
    .replace(OKOBAUDAT_ID_PREFIX, '')
    .replace(NODON_ID_PREFIX, '')
    .replace('sv-SE_', '')
    .replace(/-\d+$/g, ''); // Remove climate improved suffixes
};

export const getProductCategories = (
  product: IProduct,
  key: keyof ProductCategories,
): string[] => {
  if (key === 'Custom') {
    return ['Custom'];
  }
  const main = product.categories[key] ?? {};
  return typeof main === 'boolean'
    ? [key]
    : Object.keys(main).filter((k) => main[k]);
};

/**
 * Replace all product ids in a project with a new id
 * @param project
 * @param replaceRecord A record looking like: <Id to replace, New product>
 * @returns
 */
export const replaceProductIds = (
  project: Project,
  replaceRecord: ProductRecord,
): Project => {
  if (Object.keys(replaceRecord).length === 0) {
    return project;
  }
  const versions = getAllBuildingVersions(project);
  const productElements = getAllProductElements(...versions).filter(
    (e) => !!replaceRecord[e.product_id],
  );

  const changes = productElements.map((e) => {
    const product = replaceRecord[e.product_id];

    return {
      id: e.id,
      product_id: product?.id,
    };
  });
  return updateElements(project, ...changes);
};

export const parseProduct = (raw: ProductJSON): IProduct => {
  const created_at = DateTime.fromISO(raw.created_at).toString();
  const updated_at = DateTime.fromISO(raw.updated_at).toString();
  const deleted_at = raw.deleted_at
    ? DateTime.fromISO(raw.deleted_at).toString()
    : undefined;

  return {
    ...raw,
    created_at,
    updated_at,
    deleted_at,
    description: raw.description ?? undefined,
    owner: raw.owner ?? undefined,
    organizations: raw.organizations ?? undefined,
  };
};

export const productIDregex = /^(boverket_sv-SE_\d{10}|-)$/;
export const productNodonIDregex = /^(nodon_sv-SE_\d{10}|-)$/;

/**
 * Check if two elements contain the same products
 * @param elementA
 * @param elementB
 * @returns
 */
export const hasEqualProductsIds = (
  elementA: OneOfElements,
  elementB: OneOfElements,
): boolean => {
  if (elementA.kind !== elementB.kind) {
    throw new Error('Cannot compare different kinds of elements');
  }
  const productIdsA = getChildElements(elementA)
    .filter(isProductElement)
    .map((child) => child.product_id);
  const productIdsB = getChildElements(elementB)
    .filter(isProductElement)
    .map((child) => child.product_id);
  return shallowEqual(productIdsA, productIdsB);
};

export const convertToProductsRecord = (products: IProduct[]): ProductRecord =>
  products.reduce<ProductRecord>(
    (acc, product) => ({
      ...acc,
      [product.id]: product,
    }),
    {},
  );

/**
 * Add a product id to a list of generic ids if it's not already included
 * @param ids
 * @param id
 * @returns
 */
export const addIdToGenericIds = (
  ids: ProductID[] | undefined,
  id: ProductID,
): ProductID[] => {
  if (!ids) {
    return [id];
  }
  return ids.includes(id) ? ids : [...ids, id];
};

export const removeIdFromGenericIds = (
  ids: ProductID[],
  id: ProductID,
): ProductID[] => ids.filter((i) => i !== id);

export const getProductSourceFromId = (
  id?: ProductID,
): IProduct['source'] | undefined => {
  if (!id) {
    return;
  }
  if (isBoverketProduct(id)) {
    return 'Boverket';
  }
  if (isNodonProduct(id)) {
    return 'Nodon';
  }
  if (isOekobaudatProduct(id)) {
    return 'ökobaudat';
  }
  if (isCustomProduct(id)) {
    return 'custom';
  }
};
