import React, { useCallback, useMemo } from 'react';
import { ListItem } from '@mui/material';
import {
  IElement,
  IElementID,
  OneOfElements,
  OneOfListElements,
} from '../../../../shared/models/project.interface';
import { HideOutsideViewport } from './../HideOutsideViewport';
import Draggable from './../drag-and-drop/Draggable';
import Droppable, { DropType } from './../drag-and-drop/Droppable';
import { useMoveElements } from '../../hooks/move-elements.hook';
import {
  getElementById,
  isProductElement,
  isElement,
  isProductListElement,
} from '../../../../shared/helpers/recursive_element_helpers';
import { getSelectedVersion, useUIState } from '../../store/ui';
import { useNavigateTo } from '../../hooks/router.hooks';
import {
  isElementExpanded,
  setElementExpanded,
} from '../../hooks/expand-elements.hook';
import {
  isProjectFolder,
  isProjectInfo,
  isProjectInfoOrFolder,
} from '../../../../shared/helpers/project-folder.helpers';
import ElementListItem from './ListItems/ElementListItem';
import { ELEMENT_LIST_ITEM_HEIGHT } from './list.constants';
import {
  useAllowedDropTypes,
  useIsDraggableElement,
} from '../drag-and-drop/drag-and-drop.hook';
import ProductElementListItem from './ListItems/ProductElementListItem';
import { useProjectSelectorDrop } from '../../hooks/droppable.hook';
import ProjectListItem from './ListItems/ProjectListItem/ProjectListItem';
import { INestedListBase } from './NestedElementList';
import ProductListItem from './ListItems/ProductListItem';

interface INestedElementItemProps<T extends OneOfListElements>
  extends INestedListBase {
  element: OneOfElements<T>;
}

const NestedElementItem = <T extends OneOfListElements>({
  element,
  indentation = 0,
}: INestedElementItemProps<T>) => {
  const isProjectListItem = isProjectInfoOrFolder(element);

  const { moveElementsTo: moveElements, isMoveElementsAllowed } =
    useMoveElements();
  const {
    recentlyUndoRedoElementId,
    observedElementIds,
    addObservedElementIds,
    addedElementId,
  } = useUIState(
    'recentlyUndoRedoElementId',
    'renamingId',
    'observedElementIds',
    'addObservedElementIds',
    'addedElementId',
  );

  const draggable = useIsDraggableElement(element);
  const allowedDrops = useAllowedDropTypes(element);
  const navigateTo = useNavigateTo();
  const onProjectDrop = useProjectSelectorDrop();

  // TODO: Why this for version?
  // const isRecentlyChanged = !!(
  //   recentlyUndoRedoElementId &&
  //   ((recentlyUndoRedoElementId === 'version' &&
  //     element?.kind === ElementKind.Version) ||
  //     recentlyUndoRedoElementId === element?.id)
  // );

  const expanded = isElementExpanded(element);
  const virtualScroll = true;
  const isLastAdded = addedElementId === element.id;

  const isRecentlyChanged = !!(
    recentlyUndoRedoElementId && recentlyUndoRedoElementId === element?.id
  );
  const alwaysRender =
    !virtualScroll ||
    observedElementIds[element?.id ?? ''] ||
    (isProjectFolder(element) && isLastAdded);

  const handleElementDrop = useCallback(
    async (itemId: string, moveToId?: string | null, moveType?: DropType) => {
      if (!moveToId || !moveType) {
        throw new Error('No target element id');
      }

      await moveElements(moveToId, moveType, itemId);

      if (moveType === 'inside' && !expanded) {
        const version = getSelectedVersion();
        const parent = getElementById(version, moveToId);
        const elementsInParent =
          (parent as IElement | undefined)?.elements ?? [];

        addObservedElementIds(
          elementsInParent.reduce(
            (acc, { id }) =>
              ({
                ...acc,
                [id]: true,
              }) as Record<IElementID, boolean>,
            {},
          ) ?? {},
        );
        setElementExpanded(moveToId, true);
      }

      navigateTo({ elementId: itemId });
    },
    [moveElements, expanded, navigateTo, addObservedElementIds],
  );

  const validateDrop = useCallback(
    (itemId: string, moveToId?: string, moveType?: DropType) =>
      isMoveElementsAllowed(moveToId, moveType, itemId),
    [isMoveElementsAllowed],
  );

  const component = useMemo(() => {
    if (isProjectInfo(element) || isProjectFolder(element)) {
      return (
        <ProjectListItem
          item={element}
          indentation={indentation}
          autoFocus={isLastAdded}
        />
      );
    }
    if (isElement(element)) {
      return <ElementListItem element={element} indentation={indentation} />;
    }
    if (isProductElement(element)) {
      return (
        <ProductElementListItem element={element} indentation={indentation} />
      );
    }
    if (isProductListElement(element)) {
      return <ProductListItem element={element} indentation={indentation} />;
    }
    return <></>;
  }, [element, indentation, isLastAdded]);

  return (
    <HideOutsideViewport
      alwaysVisible={alwaysRender}
      height={ELEMENT_LIST_ITEM_HEIGHT}
      scrollTo={isRecentlyChanged || isLastAdded}
    >
      <Droppable
        onDrop={isProjectListItem ? onProjectDrop : handleElementDrop}
        allowDropInside={allowedDrops.inside}
        allowDropAbove={allowedDrops.above}
        allowDropBelow={allowedDrops.below}
        indentation={indentation}
        dropId={element?.id}
        validateFn={isProjectListItem ? SKIP_VALIDATION : validateDrop}
      >
        <Draggable dragId={element?.id} disabled={!draggable}>
          <ListItem disablePadding disableGutters>
            {component}
          </ListItem>
        </Draggable>
      </Droppable>
    </HideOutsideViewport>
  );
};

// TODO: Should validate project list items
const SKIP_VALIDATION = () => true;

export default React.memo(NestedElementItem);
