import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Popover,
  Typography,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { setLocale, useLocale } from '../providers/LocaleProvider';
import { isAdmin } from '../../../shared/helpers/user.helpers';
import amplitudeLog from '../amplitude';
import { capitalize } from 'lodash';
import { useNavigateTo } from '../hooks/router.hooks';
import { User } from '../../../shared/models/user.interface';

interface ProfileMenuProps {
  user: User;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ user }) => {
  const navigateTo = useNavigateTo();
  const [, localeDispatch] = useLocale();

  const { classes } = useStyles();
  const auth0 = useAuth0();

  useEffect(() => {
    setLocale('en-US', localeDispatch);
  }, [localeDispatch]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = useMemo(() => !!anchorEl, [anchorEl]);

  const goToCalculationsPage: MouseEventHandler<HTMLElement> = useCallback(
    () => navigateTo({ page: 'calculations' }),
    [navigateTo],
  );

  const handleIconClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => setAnchorEl(e.currentTarget),
    [setAnchorEl],
  );

  const handlePopoverClick: MouseEventHandler<HTMLDivElement> = useCallback(
    () => setAnchorEl(null),
    [setAnchorEl],
  );

  const handleLogoutClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (user) {
        amplitudeLog('Logout');
      }

      if (window._hsq) {
        // reset hubspot cookies in order to track current user on next login
        window._hsq.push(['revokeCookieConsent']);
      }

      window.sessionStorage.setItem('isLoggedIn', 'false');

      auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }, [user, auth0]);

  if (!user) {
    return null;
  }
  return (
    <Box data-keep-project-selector-open>
      <IconButton onClick={handleIconClick} size="large">
        <Avatar alt={user.nickname} src={user.picture} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClick={handlePopoverClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Card>
          <Box p={2}>
            <CardContent>
              <Grid container spacing={1} minWidth={'200px'}>
                <Grid item xs={3}>
                  <Avatar
                    alt={user.nickname}
                    src={user?.picture || undefined}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    <b>{capitalize(user.nickname)}</b>
                  </Typography>
                  <Typography variant="body2">{user.name}</Typography>
                </Grid>
                {isAdmin(user) && (
                  <div>
                    <Typography variant="h6" mt={4} style={{ fontSize: 16 }}>
                      Admin Area
                    </Typography>
                    <Typography
                      className={classes.adminOption}
                      variant="body2"
                      onClick={goToCalculationsPage}
                    >
                      Calculations
                    </Typography>
                  </div>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              {/* <Button
              color="primary"
              size="small"
              onClick={() =>
                setLocale(
                  localeState.locale === 'en-US' ? 'sv-SE' : 'en-US',
                  localeDispatch,
                )
              }
            >
              <Language />
              {localeState.locale}
            </Button> */}
              <Button
                color="primary"
                size="small"
                onClick={handleLogoutClick}
                style={{ marginLeft: 'auto' }}
              >
                Logout
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Popover>
    </Box>
  );
};

const useStyles = makeStyles()(({ palette }: Theme) => ({
  adminOption: {
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': { color: palette.text.secondary },
  },
}));

export default ProfileMenu;
