import React, { useMemo, useRef } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  BarChartData,
  BarChartTooltipContext,
} from '../../../../shared/models/chart.interface';
import { getDatasets } from '../../helpers/chart_helpers';
import { makeStyles } from 'tss-react/mui';
import { BarChartTooltipContainerStyle } from '../../style/constants';

Chart.register(LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
  data: BarChartData;
  maxValue: number;
  horizontal?: boolean;
  color?: string;
  barPercentage?: number;
  animationDelay?: number;
  labelFormatter?: () => string;
  renderTooltip?: (context: BarChartTooltipContext) => void;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  maxValue,
  horizontal,
  color,
  barPercentage,
  animationDelay = 50,
  labelFormatter,
  renderTooltip,
}) => {
  const { classes } = useStyles();
  const isAnimated = useRef(false);

  const barData: ChartData<'bar', BarChartData[], string> = useMemo(() => {
    return {
      labels: [''],
      datasets: getDatasets(
        data,
        {
          barPercentage,
          backgroundColor: color,
          datalabels: {
            formatter: labelFormatter,
            display: !!labelFormatter,
            anchor: 'end',
            align: 'right',
          },
        },
        horizontal,
      ),
    };
  }, [barPercentage, color, data, horizontal, labelFormatter]);

  const barOptions: ChartOptions<'bar'> = useMemo(() => {
    return {
      indexAxis: horizontal ? 'y' : 'x',
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: -8,
          bottom: -8,
          right: !horizontal ? 0 : 150,
        },
      },
      animation: isAnimated.current
        ? false
        : {
            duration: 1000,
            delay: animationDelay,
            onProgress: () => (isAnimated.current = true),
          },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          padding: 6,
          external: renderTooltip,
        },
      },
      scales: {
        x: {
          max: maxValue,
          stacked: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          max: maxValue,
          stacked: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };
  }, [horizontal, animationDelay, renderTooltip, maxValue]);

  return (
    <>
      <div id="custom-tooltip" className={classes.tooltip}></div>
      <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />
    </>
  );
};

const useStyles = makeStyles()(({ zIndex }) => ({
  tooltip: {
    pointerEvents: 'none',
    zIndex: zIndex.tooltip,
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    ...BarChartTooltipContainerStyle,
  },
}));

export default BarChart;
