import {
  ElementKind,
  IBuildingVersion,
  OneOfElements,
  OneOfParentElements,
  Project,
  IBuilding,
  ProjectMetadata,
  IProjectInfo,
  OneOfChildElements,
  IElement,
  OneOfListElements,
  OneOfElementListElements,
  OneOfElementListChildren,
  TrimmedProject,
} from '../models/project.interface';
import {
  applyChanges,
  getId,
  getKeys,
  isPartialEqual,
  omit,
  omitUndefined,
  pick,
  replaceProperties,
} from './object_helpers';
import {
  getAllBuildingVersions,
  getBuilding,
  getChildElements,
  getElementById,
  isBuildingVersionElement,
  isProductElement,
  flattenElements,
  isElement,
  getPathToElement,
  OneOfSearchableElements,
  isOneOfChildElements,
  findElementAndParent,
  getBuildingVersionById,
} from './recursive_element_helpers';
import { hasDuplicates, isDefined } from './array_helpers';
import {
  ItemOrItemId,
  Merge,
  SemiPartial,
} from '../models/type_helpers.interface';
import {
  CreatedElement,
  createElementFromPartial,
  createElementOfType,
  getElementIdMap,
  IElementVersionMap,
  IFactoryVersion,
  isFactoryVersion,
  isOneOfChildFactoryElements,
  OneOfChildFactoryElements,
  OneOfFactoryElements,
} from './element_factory_helpers';
import { current, produce } from 'immer';
import { IProduct, ProductRecord } from '../models/product.interface';
import {
  applyElementCategory,
  getElementCategory,
  isElementCategoryID,
  updateElementCategoryProperties,
} from './element_category_helpers';
import { DateTime } from 'luxon';
import { autoUpdateElementQuantity } from './auto-quantity.helpers';
import { getProductsInElement, hasEqualProductsIds } from './product_helpers';
import {
  ExpressionVariablesRecord,
  applyProductVariables,
} from './expression_variables_helpers';
import { isEqual } from 'lodash';
import { findFreeName } from './string_helpers';
import { sortInsert } from './sort_helpers';
import { cleanupElementVersions } from './element-version.helpers';
import { IInsertSortPlacement } from '../models/sort.interface';
import { getElementCategoryId, requiredKind } from './element_helpers';
import {
  cleanupObsoleteElementVersionsInProposals,
  getProposalsInVersion,
  remapProposalSelections,
  updateElementActiveVersionStates,
} from './proposal.helpers';
import { RecipeRecord } from '../models/recipe.interface';
import { getRecipeName } from './recipe_helpers';
import { RegenerateIds } from './id.helpers';
import { BUILDING_LIFETIME_DEFAULT } from './project_factory_helpers';
import { emptyConversionFactors } from '../models/unit.interface';
import { required } from './function_helpers';

export const DUMMY_RECIPE_ID = 'DUMMY_RECIPE_ID';

/**
 * Get a version by id and throw error if it not exist.
 * If you wanna check if a version exist or not use Array.some instead.
 * TODO: REMOVE, use getBuildingVersionById instead
 * @param project
 * @param id
 * @param throwIfNotFound Defaults to false
 */
export const getVersionById = <
  T extends true | false | undefined = true,
  R = T extends true ? IBuildingVersion : IBuildingVersion | undefined,
>(
  project: Project | IBuilding | IBuildingVersion,
  id: string,
  throwIfNotFound: T = true as T,
): R => {
  const version = getAllBuildingVersions(project).find((v) => v.id === id);

  if (!version && throwIfNotFound) {
    throw new Error('could not find version');
  }
  return version as R;
};

export const getProjectMeta = (project: Project): ProjectMetadata =>
  getBuilding(project).meta;

export const getBuildingLifetime = (project: Project): number =>
  getProjectMeta(project).building_lifetime ?? BUILDING_LIFETIME_DEFAULT;

/**
 * Update properties in building. Currently there is only one building
 * Note that the properties of the building you want to update is enough to pass.
 */
export const updateProjectProperties = (
  project: Project,
  projectChanges: Partial<Project>,
): Project => {
  return produce(project, (draft) => {
    Object.assign(draft, projectChanges);
  });
};

/**
 * Update properties in building. Currently there is only one building
 * Note that the properties of the building you want to update is enough to pass.
 */
export const updateBuildingProperties = (
  project: Project,
  buildingChanges: Partial<IBuilding>,
): Project => {
  return produce(project, (draft) => {
    const draftBuilding = getBuilding(draft);
    Object.assign(draftBuilding, buildingChanges);
  });
};

export const updateProjectMetadata = (
  project: Project,
  metaUpdates: Partial<ProjectMetadata>,
): Project => {
  const meta = getProjectMeta(project);
  return updateBuildingProperties(project, {
    meta: applyChanges(meta, metaUpdates),
  });
};

/**
 * Update properties in a specific version.
 * Note that the properties of the version you want to update is enough to pass.
 * TODO: Is this really needed? Can't updateElements be used instead?
 */
export const updateBuildingVersionProperties = (
  project: Project,
  ...versionChanges: Array<SemiPartial<IBuildingVersion, 'id'>>
): Project => {
  return produce(project, (draft) => {
    versionChanges.forEach((changes) => {
      const version = getVersionById(project, changes.id);
      const draftVersion = getVersionById(draft, changes.id);
      if (!version || !draftVersion) {
        throw new Error('Could not find version to update');
      }
      // Only update if needed to not trigger to many rerenders of views
      if (hasChanges(version, changes)) {
        Object.assign(draftVersion, changes);
      }
    });
  });
};

/**
 * Update a product in a version.
 * Add product if it doesn't exist.
 * @param project
 * @param version
 * @param updatedProduct
 * @returns
 */
export const updateProductInVersion = (
  project: Project,
  version: IBuildingVersion,
  updatedProduct: IProduct,
): Project => {
  const id = updatedProduct.id;
  const { products: versionProducts } = version;

  // Don't update if the product is the same
  if (isEqual(updatedProduct, versionProducts[id])) {
    return project;
  }

  const updatedProjectProducts: Record<string, IProduct> = {
    ...versionProducts,
    [id]: omitUndefined(updatedProduct) as IProduct,
  };

  const updatedVersion: IBuildingVersion = {
    ...version,
    products: updatedProjectProducts,
  };

  return updateBuildingVersionProperties(project, updatedVersion);
};

export const removeVersionFromProject = (
  project: Project,
  versionId: string,
): Project => {
  return produce(project, (draft) => {
    const building = getBuilding(draft);
    building.versions = building.versions.filter((v) => v.id !== versionId);
    return draft;
  });
};

/**
 * Add new versions to project.
 * Can create a new or create a copy of an existing version
 * @param project
 * @param baseVersion Opional partials of versions to base the new versons on
 * @returns
 */
export const addVersionToProject = (
  project: Project,
  regenerateIds: RegenerateIds,
  ...baseVersions: Partial<IFactoryVersion>[]
): Project => {
  // Make sure to always have at least one base version
  if (baseVersions.length === 0) {
    baseVersions = [{}];
  }

  return produce(project, (draft) => {
    const building = getBuilding(draft);

    baseVersions.forEach((baseVersion) => {
      const name = findFreeName(
        building.versions,
        baseVersion.name ?? 'Version 1',
      );
      const version = createElementOfType(
        ElementKind.Version,
        {
          ...baseVersion,
          name,
        },
        regenerateIds,
      );
      building.versions = [...building.versions, version];
    });
  });
};

type AddElementsOptions = {
  regenerateIds?: boolean;
  placement?: IInsertSortPlacement;
  sibling?: OneOfChildElements;
};

/**
 * Add a new element to a element.
 * Can create a new or create a copy of an existing version
 * TODO: rewrite addElements to support adding elements in any parent
 * @param root
 * @param baseVersion
 * @returns
 */
export const addElements = <
  R extends IBuildingVersion | Project,
  T extends R extends IBuildingVersion
    ? OneOfChildFactoryElements
    : OneOfFactoryElements,
  N extends CreatedElement<T['kind']>,
>(
  root: R,
  parent: OneOfParentElements,
  { regenerateIds = true, placement = 'first', sibling }: AddElementsOptions,
  ...elementBases: T[]
): {
  root: R;
  addedElements: CreatedElement<T['kind']>[];
} => {
  // Building Versions
  const versionBases: IFactoryVersion[] = (
    elementBases as OneOfFactoryElements[]
  ).filter(isFactoryVersion);

  // Elements & ProductElements
  const childElementBases: OneOfChildFactoryElements[] = (
    elementBases as OneOfFactoryElements[]
  ).filter(isOneOfChildFactoryElements);

  const added: N[] = [];

  // Version to modify
  const version = isBuildingVersionElement(parent)
    ? parent
    : getPathToElement(root, parent)[0];

  if (!isBuildingVersionElement(version)) {
    throw new Error('No building version found for parent element');
  }

  // Add any buildingVersions first
  if (versionBases.length > 0) {
    if (isBuildingVersionElement(root)) {
      throw new Error('Can not add buildVersion to buildingVersion');
    }
    const existingVersionIds = getAllBuildingVersions(root).map(getId);
    root = addVersionToProject(root, regenerateIds, ...versionBases) as R;
    getAllBuildingVersions(root).forEach((v) => {
      if (!existingVersionIds.includes(v.id)) {
        added.push(v as N);
      }
    });
  }

  if (childElementBases.length === 0) {
    return { root, addedElements: added };
  }

  let updatedRoot = produce(root, (draft) => {
    const draftVersion = getVersionById(draft, version.id);
    const draftParent: OneOfParentElements = getElementById(
      draftVersion,
      parent.id,
    );

    // Create an array of new elements to add from the partials
    const newElements: OneOfChildElements[] = childElementBases.map(
      (partialElement) => {
        const categoryID = getElementCategoryId(partialElement);

        const { name, fallbackName } = generateElementNameAndFallbackName(
          parent,
          partialElement,
        );

        const element = createElementFromPartial(
          { ...partialElement, name, fallbackName },
          regenerateIds,
        );

        // Apply category and seed all properties etc if category is provided
        if (isElement(element) && isElementCategoryID(categoryID)) {
          return applyElementCategory(element, categoryID);
        }
        return element;
      },
    );

    const draftSibling: OneOfChildElements | undefined = sibling
      ? requiredKind(
          getElementById(draftVersion, sibling?.id),
          ElementKind.Element,
          ElementKind.Product,
        )
      : undefined;

    if (!draftParent) {
      throw new Error('Could not find parent');
    }
    if (isProductElement(draftParent)) {
      throw new Error(`Can't add elements to products`);
    }

    draftParent.elements = sortInsert(
      placement,
      getChildElements(draftParent),
      draftSibling,
      ...newElements,
    );

    added.push(...(newElements as N[]));
  });

  const isDuplicatedFromCurrentVersion = childElementBases.some((e) =>
    getElementById(version, e.id),
  );

  // Map between previous and new ids
  const idMap = getElementIdMap(
    childElementBases,
    (added as OneOfElements[]).filter(isOneOfChildElements),
    regenerateIds,
  );

  // Apply proposal selections on duplicated elements
  updatedRoot = applyProposalSelectionsOnDuplicatedElements(
    updatedRoot,
    version.id,
    idMap,
    isDuplicatedFromCurrentVersion,
  );

  const updatedVersion = getVersionById(updatedRoot, version.id);

  const addedIds = added.map(getId);

  // Get latest instances of added elements
  const addedElements = [
    ...getAllBuildingVersions(updatedRoot),
    ...flattenElements(updatedVersion),
  ].filter((e) => addedIds.includes(e.id));

  if (addedElements.length !== added.length) {
    throw new Error('Not all added elements are valid');
  }

  return {
    root: updatedRoot,
    addedElements: addedElements as CreatedElement<T['kind']>[],
  };
};

/**
 * Make sure that selections are remembered when duplicating elements
 * @param root Version or project to apply the the changes to
 * @param versionId Version to apply the changes to
 * @param idMap Mapping between old and new ids
 * @returns
 */
const applyProposalSelectionsOnDuplicatedElements = <
  R extends IBuildingVersion | Project,
>(
  root: R,
  versionId: IBuildingVersion['id'],
  idMap: IElementVersionMap | undefined,
  isDuplicatedFromCurrentVersion?: boolean,
): R => {
  if (!idMap) {
    return root;
  }
  const version = getVersionById(root, versionId);
  const proposals = getProposalsInVersion(version).map((p) => ({
    ...p,
    selections: remapProposalSelections(p.selections, idMap, {
      keepOriginalSelections: isDuplicatedFromCurrentVersion, // Keep original selections if the element is duplicated from the current version
      selectActiveElements: !!p.active && !isDuplicatedFromCurrentVersion, // Activate elements that were active previously
    }),
  }));

  // If the selections are the same, don't update project/version
  // if (
  //   shallowEqual(
  //     version?.proposals?.map((p) => p.selections),
  //     proposals?.map((p) => p.selections),
  //   )
  // ) {
  //   return root;
  // }
  return updateElements<IBuildingVersion, R>(
    root,
    updateElementActiveVersionStates({
      ...version,
      proposals,
    }),
  );
};

/**
 * Replace a building version with a new version (if changed)
 * @param project
 * @param version
 * @returns
 */
export const replaceBuildingVersion = (
  project: Project,
  version: IBuildingVersion,
): Project => {
  const prevVersion = getVersionById(project, version.id, false);

  if (!prevVersion || prevVersion === version) {
    return project;
  }

  return produce(project, (draft) => {
    const building = getBuilding(draft);
    building.versions = building.versions.map((v) =>
      v.id === version.id ? version : v,
    );
  });
};

/**
 * Update elements with one or more changes.
 * Note. Passing undefined properties will update the property with an undefined value.
 * @param project
 * @param elementChanges
 * @returns
 */
export const updateElements = <
  T extends OneOfElements,
  R extends OneOfSearchableElements,
>(
  root: R,
  ...elementChanges: (SemiPartial<T, 'id'> | undefined)[]
): R => {
  const changes = elementChanges.filter(isDefined);

  // Throw error if duplicate updates since they might overwrite each other
  if (hasDuplicates(changes.map((e) => e.id))) {
    throw new Error('Duplicate element updates');
  }
  // Return root unmodified if no changes
  if (!changes.length) {
    return root;
  }

  return produce(root, (draft) => {
    changes.filter(isDefined).forEach((changes) => {
      const element = getElementById(root, changes.id);
      const draftElement = getElementById(draft, changes.id);

      if (!element || !draftElement) {
        throw new Error('Could not find element to update');
      }
      // Only update if needed to not trigger to many rerenders of views
      if (hasChanges(element, changes as Partial<T>)) {
        Object.assign(draftElement, changes);
      }
    });
  });
};

export const removeBuildingVersion = (
  project: Project,
  versionOrId: ItemOrItemId<IBuildingVersion>,
): Project => {
  const id = getId(versionOrId);
  return produce(project, (draft) => {
    const building = getBuilding(draft);
    getVersionById(building, id, true); // Crash if version is not found
    building.versions = building.versions.filter((v) => v.id !== id);
  });
};

/**
 * Remove elements from a project or version
 * @param root Project or Version to remove elements from
 * @param elementOrIds Elements or ids to remove
 * @returns
 */
export const removeElements = <
  R extends IBuildingVersion | Project,
  T extends R extends IBuildingVersion ? OneOfChildElements : OneOfElements,
>(
  root: R,
  ...elementOrIds: ItemOrItemId<T>[]
): R => {
  // Ids beloning to versions
  const versionIds = elementOrIds
    .map(getId)
    .filter((id) => getVersionById(root, id, false));

  // Ids beloning to elements
  const elementIds = elementOrIds
    .map(getId)
    .filter((id) => !versionIds.includes(id));

  if (versionIds.length) {
    for (const id of versionIds) {
      if (isBuildingVersionElement(root)) {
        throw new Error(
          'Need to pass a Project as root to remove building versions',
        );
      }
      root = removeBuildingVersion(root, id) as R;
    }
  }

  return produce(root, (draft) => {
    // const draftVersion =  getVersionById(draft, version.id, true);

    const updatedVersions: IBuildingVersion[] = [];

    elementIds.map(getId).forEach((id) => {
      const { parent, element, path } = findElementAndParent(draft, id);
      const version = requiredKind(path[0], ElementKind.Version);

      if (!parent || !element) {
        throw new Error('Could not find parent element to remove');
      }

      parent.elements = parent.elements.filter((e) => e.id !== id);

      // Remove obsolete element versions
      parent.elements = cleanupElementVersions(version, parent).elements;

      updatedVersions.push(version);
    });

    if (updatedVersions.length > 0) {
      // Remove any obsolete selected versions in proposals
      updatedVersions.forEach((version) => {
        version.proposals = cleanupObsoleteElementVersionsInProposals(
          current(version), // Don't pass immer object but use a regular object
        ).proposals;
      });
    }
  });
};

export const generateElementNameAndFallbackName = (
  parent: OneOfParentElements,
  elementBase: OneOfChildFactoryElements,
): { name?: string; fallbackName: string } => {
  const name =
    isElement(elementBase) && elementBase.name
      ? getNewNameOfElement(parent, elementBase)
      : undefined;
  const fallbackName = generateElementFallbackName(
    parent,
    elementBase,
    undefined,
    true,
  );

  return { name, fallbackName };
};

/**
 * Get a new name for the element and increases the number if it already exists
 * @param parent
 * @param elementBase
 * @returns
 */
export const getNewNameOfElement = (
  parent: OneOfElements,
  elementBase: Pick<Merge<OneOfListElements>, 'name' | 'kind'>,
): string | undefined => {
  // Products doesn't have names
  if (elementBase.kind === ElementKind.Product) {
    return undefined;
  }

  const defaultName =
    elementBase.kind === ElementKind.Version ? 'Version 1' : 'Element 1';

  return findFreeName(
    getChildElements(parent)
      .map((el) => ('name' in el ? el.name : undefined))
      .filter(isDefined),
    elementBase.name ?? defaultName,
  );
};

/**
 * Get all names amd fallbackNmaes from the version
 * @param version
 * @returns
 */
const getNamesAndFallbackNames = (root: OneOfParentElements) => {
  const flattenedElements = flattenElements(root);

  const names = flattenedElements
    .map((el) => ('name' in el ? el.name : undefined))
    .filter(isDefined);

  const fallbackNames = flattenedElements
    .map((el) => ('fallbackName' in el ? el.fallbackName : undefined))
    .filter(isDefined);

  const set = new Set([...names, ...fallbackNames]);

  return [...set];
};

export const getFallbackName = (
  element: OneOfElements | OneOfFactoryElements,
): string | undefined =>
  'fallbackName' in element ? element.fallbackName : undefined;

/**
 * Generate an available fallback name for an element
 * @param root
 * @param element
 * @param recipeLookup
 * @returns
 */
export const generateElementFallbackName = (
  root: OneOfParentElements,
  element?: OneOfElements | OneOfFactoryElements,
  recipeLookup?: RecipeRecord,
  forceRegeneration = false,
): string => {
  if (!element) {
    return getAvailableNameInElement(root) ?? '';
  }

  const currentFallback = getFallbackName(element);
  const recipeName = getRecipeName(element, recipeLookup);

  const categoryId = getElementCategoryId(element);
  const categoryName = categoryId
    ? (getElementCategory(categoryId)?.name ?? 'None')
    : undefined;

  const baseName = recipeName ?? categoryName ?? 'Element';

  // Keep current if it has not been changed (if duplicating we wanna force regeneration)
  if (currentFallback?.startsWith(baseName) && !forceRegeneration) {
    return currentFallback;
  }

  return getAvailableNameInElement(root, baseName) ?? '';
};

/**
 * Get a new name for the element and increases the number if it already exists
 * @param version
 * @param baseName Base name, will increment from there if name already exists
 * @returns
 */
export const getAvailableNameInElement = (
  root: OneOfParentElements,
  baseName = 'Element 1',
): string | undefined => {
  return findFreeName(getNamesAndFallbackNames(root), baseName);
};

/**
 * Test if the element has any changes
 * @param element
 * @param changes
 * @param keys If keys are provided, only those keys will be checked for changes
 * @returns
 */
const hasChanges = <T extends OneOfElements>(
  element: T,
  changes: Partial<T>,
  ...keys: (keyof T)[]
): boolean =>
  element !== changes && // Don't check properties if the element is the same as the changes
  !isPartialEqual(element, keys.length ? pick(changes, ...keys) : changes);

const IGNORED_MODIFIED_KEYS: (keyof Merge<OneOfElementListElements>)[] = [
  'elements',
  'results',
];

/**
 * Get all changed elements
 * (ignoring changes to element.children array since that always change when a child changes)
 * @param nextProject
 * @param prevProject
 * @returns
 */
export const getModifiedElements = <T extends Project | IBuildingVersion>(
  next: T,
  prev: T | undefined,
  onlyRootElements = true,
): OneOfElements[] => {
  if (next === prev) {
    return [];
  }

  const nextVersions = getAllBuildingVersions(next);
  const prevVersions = getAllBuildingVersions(prev);

  // Going through elements in all versions are expensive, so first check which versions have changed
  const changedVersions = nextVersions.filter((nextVersion) => {
    const prevVersion = prevVersions.find((v) => v.id === nextVersion.id);
    return !prevVersion || prevVersion !== nextVersion;
  });

  const nextElements = flattenElements(
    ...changedVersions.flatMap((v) => v.elements),
  );
  const prevElements = flattenElements(
    ...prevVersions
      .filter((v) => changedVersions.some((c) => c.id === v.id))
      .flatMap((v) => v.elements),
  );

  const changedElements = nextElements.filter((next) => {
    const prev = prevElements.find((p) => p.id === next.id);
    if (!prev) {
      return true;
    }
    if (prev === next) {
      return false;
    }

    // If a child has been added or removed, the element has changed
    if (!hasEqualProductsIds(next, prev)) {
      return true;
    }

    // Ignore children when checking for changes since they always change when a child changes
    const keys = getKeys(next).filter(
      (k) => !IGNORED_MODIFIED_KEYS.includes(k),
    );

    return hasChanges(next, prev, ...keys);
  });

  // If onlyRootElements is true, only the top element of the changes, not the changed children
  if (onlyRootElements) {
    const children = changedElements.flatMap((e) => getChildElements(e));
    return changedElements.filter((e) => !children.includes(e));
  }

  return changedElements;
};

export const isProjectsEqual = (a: Project, b: Project): boolean =>
  isEqual(omit(a, 'updated_at'), omit(b, 'updated_at'));

export const sortProjectsByLastUpdated = (
  projects: IProjectInfo[],
): IProjectInfo[] => {
  return [...projects].sort(
    (a, b) =>
      DateTime.fromISO(b.updated_at).valueOf() -
      DateTime.fromISO(a.updated_at).valueOf(),
  );
};

const isTrimmedProject = (project: any): project is TrimmedProject =>
  'metadata' in project && 'version_ids' in project;

const getProjectGfa = ({
  gfa_building,
  building_footprint,
  storeys,
}: ProjectMetadata) => {
  if (!gfa_building) {
    return (building_footprint?.area ?? 0) * (storeys?.length ?? 0);
  }
  return gfa_building;
};

/**
 * Convert Project to ProjectInfo
 * @param project
 * @returns
 */
export const projectToProjectInfo = (
  project: Project | TrimmedProject,
): IProjectInfo => {
  const mutual: Omit<IProjectInfo, 'versionIds' | 'gfa'> = {
    ...omit(project, 'template', 'buildings', 'metadata', 'version_ids'),
    id: String(project.id),
    kind: ElementKind.ProjectInfo,
    parent_id: project.parent_id === undefined ? null : project.parent_id,
    location: project.location ?? 0,
  };

  if (isTrimmedProject(project)) {
    return {
      ...mutual,
      versionIds: project.version_ids,
      gfa: getProjectGfa(project.metadata),
    };
  }
  return {
    ...mutual,
    versionIds: getAllBuildingVersions(project).map(getId),
    gfa: getProjectGfa(getProjectMeta(project)),
  };
};

/**
 * Will apply category properties and quantity properties to an element
 * and update children accordingly
 * @param element
 * @param path
 * @returns
 */
export const enrichElementStructure = <T extends OneOfElements>(
  element: T,
  path: OneOfParentElements[] = [],
  variablesRecord: ExpressionVariablesRecord = {},
  productRecord: ProductRecord = {},
): T => {
  if (isElement(element)) {
    let updatedElement: IElement = updateElementCategoryProperties(element);

    // // Products might have changed in the above call
    variablesRecord = updateProductIdsInVariablesRecord(
      updatedElement,
      variablesRecord,
      productRecord,
    );

    // Apply auto or clear quantities if possible
    updatedElement = autoUpdateElementQuantity(
      updatedElement,
      path,
      variablesRecord,
    );

    // Update element if it has changes
    if (updatedElement !== element) {
      element = updatedElement as T;
    }
  }

  // Recursively update children
  if (isElement(element) || isBuildingVersionElement(element)) {
    const updatedChildren: OneOfElementListChildren[] = element.elements.map(
      (child) =>
        enrichElementStructure(
          child,
          [...path, element as OneOfParentElements],
          variablesRecord,
          productRecord,
        ),
    );
    // Update element if children has changed
    element = replaceProperties(element, {
      elements: updatedChildren,
    } as Partial<typeof element>);
  }

  return element;
};

const updateProductIdsInVariablesRecord = (
  element: OneOfElements,
  variablesRecord: ExpressionVariablesRecord,
  productRecord: ProductRecord = {},
): ExpressionVariablesRecord => {
  if (getKeys(productRecord).length === 0) {
    return variablesRecord;
  }
  const vars = variablesRecord[element.id];

  if (vars) {
    // Get all products in the element (IN ORDER!!!)
    const products = getProductsInElement(element, productRecord);
    const updatedVars = applyProductVariables(vars, products);
    if (updatedVars !== vars) {
      return {
        ...variablesRecord,
        [element.id]: updatedVars,
      };
    }
  }
  return variablesRecord;
};

/**
 * Populate project with active version id and results from the active version
 * @param project
 * @param newActiveVersionId Provide to select a new active version, otherwise will use project.active_version_id or first version id if not provided
 * @returns
 */
export const applyVersionMetadata = (
  project: Project,
  newActiveVersionId?: IBuildingVersion['id'],
): Project => {
  const active_version_id = newActiveVersionId ?? project.active_version_id;
  const firstVersion = required(getAllBuildingVersions(project)[0]);

  const version =
    getBuildingVersionById(project, active_version_id) ?? firstVersion;

  // Replace active version id and results if they have changed
  return replaceProperties(project, {
    active_version_id: version.id,
    results: version.results ?? { ...emptyConversionFactors },
  });
};
