import React, { useCallback, useMemo, MouseEvent } from 'react';
import { OutlinedTextFieldProps, Box, Tooltip } from '@mui/material';
import {
  isProjectFolder,
  isProjectInfo,
} from '../../../../../../shared/helpers/project-folder.helpers';
import amplitudeLog from '../../../../amplitude';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import { useBooleanState } from '../../../../hooks/hooks';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { useProjectId, useProjectState } from '../../../../store/project';
import InlineTextField from '../../../InlineTextField';
import { IProjectFolder } from '../../../../../../shared/models/folder.interface';
import { IProjectInfo } from '../../../../../../shared/models/project.interface';
import { useNavigateTo } from '../../../../hooks/router.hooks';
import { useFolderState } from '../../../../store/folder';
import { useUIState } from '../../../../store/ui';

interface ProjectListItemNameInputProps {
  item: IProjectFolder | IProjectInfo;
  isEditingName: boolean;
  autoFocus?: boolean;
  onClick: (e: MouseEvent) => void | undefined;
  onCancel: () => void;
}

const ProjectListItemNameInput: React.FC<ProjectListItemNameInputProps> = ({
  item,
  autoFocus = false,
  isEditingName,
  onClick,
  onCancel,
}) => {
  const { name, id } = item;

  const { updateProjectDetails } = useProjectState('updateProjectDetails');
  const { setAddedElementId, setShowProjectSelector } = useUIState(
    'setAddedElementId',
    'setShowProjectSelector',
  );
  const { updateFolder } = useFolderState('folders', 'updateFolder');

  const navigateTo = useNavigateTo();
  const isFolder = isProjectFolder(item);
  const projectId = useProjectId();
  const readonly = useIsReadonly();

  const [nameTooltipIsOpen, showNameTooltip, hideNameTooltip] =
    useBooleanState(false);

  const saveName = useCallback(
    async (name: string) => {
      onCancel();

      if (isProjectFolder(item)) {
        await updateFolder({ ...item, name });
      }
      if (isProjectInfo(item)) {
        await updateProjectDetails({
          projectId: parseInt(id),
          data: { name },
        });
      }

      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: id,
      });
    },
    [onCancel, item, id, updateFolder, updateProjectDetails],
  );

  const handleNameClick = useMouseEventCallback(
    (e: MouseEvent) => {
      if (isFolder || isEditingName) {
        onClick(e);
        return;
      }
      setShowProjectSelector(false);
      navigateTo({ projectId: id });
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
      onBlur: () => {
        if (autoFocus) {
          setAddedElementId(undefined);
        }
      },
      sx: { cursor: isFolder ? 'auto' : 'pointer', maxWidth: 440 },
    }),
    [autoFocus, isFolder, setAddedElementId],
  );

  return (
    <Tooltip
      open={nameTooltipIsOpen}
      title={!isFolder && !isEditingName ? 'Open project' : ''}
      onMouseOver={showNameTooltip}
      onMouseOut={hideNameTooltip}
    >
      <Box width="100%">
        <InlineTextField
          autoFocus
          variant="subtitle1"
          value={name}
          editing={isEditingName}
          readonly={readonly || !isEditingName}
          allowClickEventWhenDisabled
          textFieldProps={nameInputProps}
          fullWidth={isEditingName}
          bold={id === String(projectId) && !isEditingName}
          onSave={saveName}
          onCancel={onCancel}
          onClick={handleNameClick}
        />
      </Box>
    </Tooltip>
  );
};

export default ProjectListItemNameInput;
