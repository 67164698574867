import React, { useMemo } from 'react';
import { IProjectInfo } from '../../../../../../shared/models/project.interface';
import { IProjectFolder } from '../../../../../../shared/models/folder.interface';
import { useIsMenuOpen } from '../../../../hooks/menu.hooks';
import ProjectFolderKebabMenu from '../../../menus/KebabMenu/KebabMenus/ProjectFolderKebabMenu';
import ProjectInfoKebabMenu from '../../../menus/KebabMenu/KebabMenus/ProjectInfoKebabMenu';
import { ProjectListItemMenuProps } from '../../../menus/menu.model';
import { isProjectFolder } from '../../../../../../shared/helpers/project-folder.helpers';

interface ProjectListItemKebabMenuProps {
  item: IProjectInfo | IProjectFolder;
  anchor?: Element;
  hover: boolean;
  onOpen: (anchor?: Element) => void;
  onClose: () => void;
  onEdit: () => void;
}

const ProjectListItemKebabMenu = ({
  item,
  anchor,
  hover,
  onOpen,
  onClose,
  onEdit,
}: ProjectListItemKebabMenuProps) => {
  const isKebabMenuOpen = useIsMenuOpen(item.id);

  const kebabMenuProps = useMemo(
    (): ProjectListItemMenuProps => ({
      item,
      anchor,
      onOpen,
      onClose,
      onEdit,
    }),
    [item, anchor, onOpen, onClose, onEdit],
  );

  if (!isKebabMenuOpen && !anchor && !hover) {
    return null;
  }

  return isProjectFolder(item) ? (
    <ProjectFolderKebabMenu {...kebabMenuProps} />
  ) : (
    <ProjectInfoKebabMenu {...kebabMenuProps} />
  );
};

export default ProjectListItemKebabMenu;
