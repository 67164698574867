import React from 'react';
import {
  useMainElementCategoryConversionFactorRecord,
  useMaxValueOfCategoryConversionFactors,
} from '../../../hooks/element-category.hook';
import { List } from '@mui/material';
import MainCategoryItem from './MainCategoryItem';
import { orderMainCategoryFactors } from '../../../../../shared/helpers/element_category_helpers';
import { mainCategoryElementsAreValid } from '../../../../../shared/templates/categories';
import { useSelectedVersion } from '../../../store/ui';

const MainCategoriesOverview = () => {
  const version = useSelectedVersion();
  const mainCategoryFactors =
    useMainElementCategoryConversionFactorRecord(version);

  const maxCO2 = useMaxValueOfCategoryConversionFactors(
    mainCategoryFactors,
    'co2e_total',
  );
  const maxCost = useMaxValueOfCategoryConversionFactors(
    mainCategoryFactors,
    'sek_A1-A3',
  );

  const mainCategoriesAreValid = mainCategoryElementsAreValid(
    version?.elements,
  );

  return version && mainCategoriesAreValid ? (
    <List sx={{ display: 'flex' }}>
      {orderMainCategoryFactors(mainCategoryFactors).flatMap(
        ([categoryId, categoryFactors]) => {
          return (
            <MainCategoryItem
              key={categoryId}
              categoryId={categoryId}
              categoryConversionFactors={categoryFactors}
              maxCO2={maxCO2}
              maxCost={maxCost}
            />
          );
        },
      )}
    </List>
  ) : (
    <></>
  );
};

export default MainCategoriesOverview;
