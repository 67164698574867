import React, { ReactNode, FC, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import Loading from './Loading';
import { useConfig } from '../providers/ConfigProvider';
import { UsersProvider } from '../providers/UsersProvider';
import { ElementMappingProvider } from '../providers/ElementMappingProvider';
import { useSelectedOrganization } from '../store/organization';
import { useLogoutIfUnauthorized } from '../hooks/hooks';
import { getUserOrganizationNames } from '../../../shared/helpers/user.helpers';
import { useUser } from '../hooks/user.hook';

interface AxiosConfigProps {
  disableAuth?: boolean;
  children: ReactNode;
}

const AxiosConfig: FC<AxiosConfigProps> = ({ disableAuth, children }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const user = useUser();
  const [config] = useConfig();
  const logoutIfUnauthorized = useLogoutIfUnauthorized();
  const selectedOrganization = useSelectedOrganization();

  const [intercepted, setIntercepted] = useState(false);

  const interceptor = useMemo(
    () =>
      async (
        request: InternalAxiosRequestConfig,
      ): Promise<InternalAxiosRequestConfig> => {
        try {
          if (
            user &&
            selectedOrganization &&
            !getUserOrganizationNames(user).includes(selectedOrganization)
          ) {
            throw new Error('User not a member of selected organization');
          }
          const token = await getAccessTokenSilently();

          return {
            ...request,
            headers: {
              ...(request.headers ?? {}),
              Authorization: `Bearer ${token}`,
              AppVersion: config.version,
              SessionId: window.sessionStorage.getItem('id'),
              Organization: selectedOrganization,
            } as unknown as AxiosRequestHeaders,
          };
        } catch (e) {
          logoutIfUnauthorized(e);
          throw e;
        }
      },
    [
      config.version,
      getAccessTokenSilently,
      logoutIfUnauthorized,
      selectedOrganization,
      user,
    ],
  );

  useEffect(() => {
    axios.defaults.baseURL = config.baseUrl;

    if (disableAuth) {
      setIntercepted(true);
      return () => {
        setIntercepted(false);
      };
    }

    if (!isLoading) {
      // Since we're only using one interceptor, we must make sure to clear it before adding a new one
      axios.interceptors.request.clear();
      const i = axios.interceptors.request.use(interceptor);
      setIntercepted(true);

      return () => {
        // HMR locally makes ejects the interceptor "mid request" which causes app to crash locally on save
        if (config.environment !== 'localhost') {
          axios.interceptors.request.eject(i);
          setIntercepted(false);
        }
      };
    }
  }, [
    config.baseUrl,
    config.contentFilterUrl,
    config.environment,
    config.version,
    disableAuth,
    interceptor,
    isLoading,
  ]);

  if ((isLoading || !intercepted) && !disableAuth) {
    return <Loading />;
  }

  return (
    <UsersProvider>
      <ElementMappingProvider>{children}</ElementMappingProvider>
    </UsersProvider>
  );
};

export const isNotFoundError = (e: unknown): boolean => {
  if (axios.isAxiosError(e)) {
    return e.response?.status === 404;
  }
  return false;
};

export default AxiosConfig;
