import React, { useCallback, useState, MouseEvent } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { elementListItemSelectIconStyles } from '../../../../style/constants';
import { useIsReadonly } from '../../../../hooks/user.hook';
import RecipeSelector from './RecipeSelector/RecipeSelector';
import { IElement } from '../../../../../../shared/models/project.interface';
import { isProductCategoryElement } from '../../../../../../shared/helpers/element_category_helpers';

interface RecipeIconButtonProps {
  element: IElement;
}

const RecipeIconButton: React.FC<RecipeIconButtonProps> = ({ element }) => {
  const readonly = useIsReadonly();
  const [anchor, setAnchor] = useState<HTMLElement>();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );

  const handleClose = useCallback(() => setAnchor(undefined), []);

  return (
    !readonly &&
    !isProductCategoryElement(element) && (
      <>
        <Tooltip title="Select recipe">
          <IconButton
            size="small"
            edge="start"
            onClick={handleClick}
            sx={elementListItemSelectIconStyles}
          >
            <ArrowDropDown />
          </IconButton>
        </Tooltip>

        {!!anchor && <RecipeSelector element={element} onClose={handleClose} />}
      </>
    )
  );
};

export default RecipeIconButton;
