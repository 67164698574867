import { useCallback, useMemo } from 'react';
import { validateRecipe } from '../../../../shared/validation';
import { Recipe } from '../../../../shared/models/recipe.interface';
import { getProductsLookup } from '../product';
import { IRecipeStoreState } from './recipe-state.model';
import { useRecipesStore } from './recipe.store';
import { useMemoDeepEqual } from '../../hooks/hooks';
import { pick } from '../../../../shared/helpers/object_helpers';
import { createLocalStorageStore } from '../../helpers/local-storage.helpers';

const { set: setLastSelectedRecipes, useStore } = createLocalStorageStore<
  string[]
>('last_selected_recipe', []);

const recipesSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['recipes'] => state.recipes;

const recipeLookupSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['recipesLookup'] => state.recipesLookup;

export function useRecipeState<K extends keyof IRecipeStoreState>(
  ...keys: K[]
): Pick<IRecipeStoreState, K> {
  // Create a memoed selector function to pick properties
  const selector = useMemoDeepEqual(
    () => (o: IRecipeStoreState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return useRecipesStore(selector);
}

export const useRecipes = (): IRecipeStoreState['recipes'] =>
  useRecipesStore(recipesSelector);

export const useRecipeLookup = (): IRecipeStoreState['recipesLookup'] =>
  useRecipesStore(recipeLookupSelector);

export const getRecipes = (): IRecipeStoreState['recipes'] =>
  recipesSelector(useRecipesStore.getState());

export const getRecipeLookup = (): IRecipeStoreState['recipesLookup'] =>
  recipeLookupSelector(useRecipesStore.getState());

export const getCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useRecipesStore.getState().createRecipe;

const getUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useRecipesStore.getState().updateRecipe;

/**
 * Create a recipe after validating it
 * @returns
 */
export const useCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const createRecipe = getCreateRecipe();
    const productRecord = getProductsLookup();
    return createRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Clone a recipe after validating it
 * @returns
 */
export const useCloneRecipe = (): IRecipeStoreState['cloneRecipe'] =>
  useCallback(
    (recipe: Recipe, destinationOrganization: string, overwrite: boolean) => {
      const cloneRecipe = useRecipesStore.getState().cloneRecipe;
      const productRecord = getProductsLookup();
      return cloneRecipe(
        validateRecipe(recipe, productRecord),
        destinationOrganization,
        overwrite,
      );
    },
    [],
  );

/**
 * Update a recipe after validating it
 * @returns
 */
export const useUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const updateRecipe = getUpdateRecipe();
    const productRecord = getProductsLookup();
    return updateRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Delete a recipe
 * @returns
 */
export const useDeleteRecipe = (): IRecipeStoreState['deleteRecipe'] =>
  useRecipesStore((state) => state.deleteRecipe);

/**
 * Get and set the last selected recipes in local storage
 * @returns
 */
export const useLastSelectedRecipe = (maxNumberOfStoredRecipes = 50) => {
  const recipes = useRecipes();
  const storedIds = useStore();

  const lastSelectedRecipes = useMemo(() => {
    const rest = recipes.filter((r) => !storedIds.includes(r.id));

    const stored: Recipe[] = [];

    // preserve same order as storedIds
    for (const id of storedIds) {
      const recipe = recipes.find((r) => r.id === id);
      if (recipe) stored.push(recipe);
    }

    return [...stored, ...rest];
  }, [recipes, storedIds]);

  const addRecipeToLastSelected = useCallback(
    (recipeId: string) => {
      const ids = (storedIds ?? []).filter((id) => id !== recipeId);
      setLastSelectedRecipes(
        [recipeId, ...ids].slice(0, maxNumberOfStoredRecipes),
      );
    },
    [maxNumberOfStoredRecipes, storedIds],
  );

  return useMemo(
    () => ({ recipes: lastSelectedRecipes, addRecipeToLastSelected }),
    [lastSelectedRecipes, addRecipeToLastSelected],
  );
};
