import {
  SBEFCode,
  SBEFCodeLanguage,
  sbefCodes,
  sbefCodesLookup,
} from '../../construction/sbef-codes';

const formatSBEFCode = (
  { id, description }: SBEFCode,
  lang: SBEFCodeLanguage = 'en',
) => `${id}. ${description[lang]}`;

export const getFormatedSBEFCodes = () => {
  return sbefCodes.map(({ id, description }) =>
    formatSBEFCode({ id, description }),
  );
};

export const getSBEFDividerItems = () => {
  const items: string[] = [];

  for (let i = 0; i <= 10; i++) {
    if (i === 10) {
      i = 101;
    }

    const item = sbefCodesLookup[i];

    if (item) {
      items.push(formatSBEFCode(item));
    }
  }
  return items;
};

export const getSBEFGroups = (
  lang: SBEFCodeLanguage = 'en',
): Record<string, string[]> => {
  return sbefCodes.reduce<Record<string, string[]>>(
    (acc, code) => {
      const name = code.id + '. ' + code.description[lang];

      // Create the SBEF main categories (groups) for single digit codes
      if (code.id.length === 1) {
        acc[name] = [];
      }

      // If the id has more than two digits (eg '101'), it belongs to an unspecified category
      if (code.id.length > 2) {
        acc['other']?.push(name);
      }

      /* 
      Place the code in the correct group, eg:
      0. group label
        00. code label
        01. code label
        ...
      */
      if (code.id.length > 1) {
        const match = Object.keys(acc).find((key) => {
          const firstChar = code.id[0];
          return firstChar && key.startsWith(firstChar);
        });

        if (match) acc[match]?.push(name);
      }

      return acc;
    },
    { other: [] },
  );
};
