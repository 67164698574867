import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IElement } from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import { getSelectedVersion } from '../../../store/ui';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import {
  useProposals,
  useSelectProposal,
  useUpdateProposals,
} from '../../../hooks/proposals.hook';
import { getNextProposal } from '../../../../../shared/helpers/proposal.helpers';
import { useIsLoading } from '../../../hooks/useIsLoading';

interface VisibilityIconProps {
  element?: IElement;
  proposal?: IProposal;
}

const VisibilityButton: React.FC<VisibilityIconProps> = ({
  element,
  proposal,
}) => {
  const updateElements = useUpdateElements();
  const selectProposal = useSelectProposal();
  const updateProposals = useUpdateProposals();

  const isLoading = useIsLoading();
  const proposals = useProposals();

  const handleClick = useMouseEventCallback(async () => {
    if (isLoading) {
      return;
    }

    if (element) {
      updateElements({
        ...element,
        isDeactivated: !element.isDeactivated,
      });
    }
    if (proposal) {
      const version = getSelectedVersion(true);

      if (!proposal.active) {
        // Hide proposal
        await updateProposals(version, {
          id: proposal.id,
          hidden: !proposal.hidden,
        });
      }

      // If the proposal is active, hide it and make the next proposal active instead
      if (proposal.active) {
        const nextProposal = getNextProposal(proposals, proposal);

        if (!nextProposal) return;

        const currentProposalPartial = {
          id: proposal.id,
          hidden: true,
        };
        const nextProposalPartial = {
          id: nextProposal.id,
          hidden: false,
        };

        await updateProposals(
          version,
          currentProposalPartial,
          nextProposalPartial,
        );
        selectProposal(nextProposal.id);
      }
    }
  });

  const icon = useMemo(
    () =>
      element?.isDeactivated || proposal?.hidden ? (
        <VisibilityOff sx={{ fontSize: 18, color: 'black' }} />
      ) : (
        <Visibility sx={{ fontSize: 18 }} color="action" />
      ),
    [element?.isDeactivated, proposal?.hidden],
  );

  const buttonStyles = useMemo(() => {
    return {
      minWidth: 40,
      height: 40,
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
      visibility: proposals.length < 2 ? 'hidden' : 'visible',
    };
  }, [proposals.length]);

  return (
    <Button sx={buttonStyles} onClick={handleClick} disabled={isLoading}>
      {icon}
    </Button>
  );
};

export default VisibilityButton;
