import React, { useCallback, useEffect, useRef } from 'react';
import { IElement } from '../../../../shared/models/project.interface';
import {
  systemCategoryIds,
  ElementCategoryID,
} from '../../../../shared/models/element_categories.interface';
import { ConcretePropertyName } from '../../../../shared/templates/categories/concrete/concrete.model';
import { ProductCategoryPropertyName } from '../../../../shared/templates/categories/processor.model';
import { useTempElement } from '../../hooks/temp-element.hook';
import { useOnce } from '../../hooks/hooks';
import Loading from '../../components/Loading';
import { useUnmount } from 'usehooks-ts';
import {
  IProduct,
  ProductID,
} from '../../../../shared/models/product.interface';
import { Box, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { getCategoryPropertyValueRecord } from '../../../../shared/helpers/element_category_helpers';
import ElementCategorySettings from '../../components/SidePanel/Element/ElementCategory/ElementCategorySettings';
import { ElementPropertyName } from '../../../../shared/models/element_property.interface';

const filterCategories = [...systemCategoryIds, ElementCategoryID.Labour];
const filterProperties = [
  ProductCategoryPropertyName.ReusedContent,
  ConcretePropertyName.ReinforcementSteel,
  ConcretePropertyName.StainlessReinforcement,
  ElementPropertyName.SBEFCode,
  ElementPropertyName.Lifetime,
];

export interface IGenericProductChange {
  product_id: ProductID | undefined;
  category_id?: ElementCategoryID;
  category_property_value_record: IProduct['category_property_value_record'];
}

interface GenericProductSelectorProps {
  /**
   * Either a product or an element to get defaults from
   */
  defaults?: IElement | IProduct;
  showPrompt?: boolean;
  onProductChange?: (change: IGenericProductChange) => void;
}

const GenericProductSelector: React.FC<GenericProductSelectorProps> = ({
  defaults,
  showPrompt,
  onProductChange,
}) => {
  const { tempElement, addTempElement, cleanupTempElement, productId } =
    useTempElement();

  const prevChangeRef = useRef<IGenericProductChange | undefined>();

  const tempRef = useRef(tempElement);
  tempRef.current = tempElement; // Do not trigger useEffect on tempElement change

  // Add temp element on mount
  useOnce(() => addTempElement(defaults));

  // Cleanup temp element on unmount
  useUnmount(cleanupTempElement);

  // Will emit changes if changes are different from previous time it was emitted
  const emit = useCallback(() => {
    if (onProductChange && tempElement) {
      const category_property_value_record =
        getCategoryPropertyValueRecord(tempElement) ?? {};
      const change: IGenericProductChange = {
        product_id: productId,
        category_id: tempElement.category_id,
        category_property_value_record,
      };
      // ONLY emit if change is different from previous
      if (!isEqual(change, prevChangeRef.current)) {
        onProductChange(change);
      }
      prevChangeRef.current = change;
    }
  }, [onProductChange, productId, tempElement]);

  // Emit changes when tempElement OR productId changes
  useEffect(emit, [emit, productId, tempElement]);

  if (!tempElement) {
    return <Loading></Loading>;
  }

  return (
    <Box>
      <Typography variant="subtitle2">Product Category</Typography>
      <ElementCategorySettings
        element={tempElement}
        filterCategories={filterCategories}
        filterProperties={filterProperties}
      />
      {showPrompt && (
        <Typography variant="caption" color="error" pl={3.5}>
          Generic product not found for the selected category
        </Typography>
      )}
    </Box>
  );
};

export default GenericProductSelector;
