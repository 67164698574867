import React from 'react';
import {
  emptyConversionFactors,
  Results,
} from '../../../../../../shared/models/unit.interface';
import {
  useProjectListMaxResults,
  useProjectListGFAScale,
} from '../../../../store/project';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { PROJECT_CO2_BAR_HEIGHT } from '../../list.constants';
import { useGetResultsPerGFA } from '../../../../hooks/results.hook';

interface ProjectListItemBarChartsProps {
  gfa: number;
  results?: Results;
}

const ProjectListItemBarCharts = ({
  gfa,
  results,
}: ProjectListItemBarChartsProps) => {
  const getResultsPerGFA = useGetResultsPerGFA();
  const maxResults = useProjectListMaxResults();
  const gfaScale = useProjectListGFAScale(gfa);

  const { co2e_total: co2ePerGFA, 'sek_A1-A3': costPerGFA } = getResultsPerGFA(
    results ?? emptyConversionFactors,
    gfa,
  );
  const { co2e_total: co2ePerGfaMax, 'sek_A1-A3': costPerGfaMax } = maxResults;

  return (
    <ResultsBarCharts
      pl={2}
      co2e={co2ePerGFA}
      co2eMax={co2ePerGfaMax}
      cost={costPerGFA}
      costMax={costPerGfaMax}
      co2eHeight={gfaScale * PROJECT_CO2_BAR_HEIGHT}
    />
  );
};

export default ProjectListItemBarCharts;
